import { Grid, Button, Typography, IconButton } from '@material-ui/core'
import { Route } from 'react-router'
import Container from '../../components/Container'
import { FaPrint } from 'react-icons/fa'
import ProgramAnual from './ProgramAnual'
import ReferateProprii from './ReferateProprii'
const Rapoarte = props => {

    const Title = () => (
        <Grid container justify = "flex-start" alignItems = "center" spacing = {2}>
            <Grid item>LISTE  ȘI RAPOARTE</Grid>
            {/* <Grid item >
                <Button size = "small" onClick = {() => props.history.push('/rapoarte/referate-proprii')}>
                    <Typography variant = "overline">
                        Referate proprii
                    </Typography>
                </Button>
            </Grid> */}
            <Grid item>
                <Button size = "small" onClick = {() => props.history.push('/rapoarte/program-anual')}>
                    <Typography variant = "overline">
                        Program de achiziții
                    </Typography>
                </Button>

            </Grid>
        </Grid>
    )

    return (
        <Container title = {<Title/>}  >
            <Route path = "/rapoarte/referate-proprii" component = {ReferateProprii} />
            <Route path = "/rapoarte/program-anual" component = {ProgramAnual} />
        </Container>
    )
}

export default Rapoarte