import { useContext, useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom'
import {Context} from '../components/Store'

import storage from '../lib/storage'
import { Toolbar } from '@material-ui/core'
import Logo from '../assets/aquis.png'
import ReferateleMele from './ReferateleMele'
import Layout from '../components/Layout'
import Referat from './Referat'
import Rapoarte from './Rapoarte'
import Programe from './Programe'
import Configurari from './Configurari'
import Registru from './Registru'



const Main = props => {
    const History = useHistory()
    const [state, dispatch] = useContext(Context)
    // console.log("main/props:", props)
    useEffect(() => {
        const ls = storage.get()
        if (!ls.token) { History.push("/login") } else {
            dispatch(ls)
        }
    }, [])
    useEffect(() => {
        const ls = storage.get()
        if (!ls.token) { History.push("/login") } else {
            dispatch(ls)
            
        }
    }, [state.token])


    return (
        // <div style = {{flexGrow:1}}>
            <Layout title = "user role">
                <Switch>
                <Route path = "/"  exact component = {ReferateleMele} />
                <Route path = "/referat/:id" exact component = {Referat} />
                <Route path = "/registru" exact component = {Registru} />
                <Route path = "/programe" exact component = {Programe} />

                <Route path = "/rapoarte"  component = {Rapoarte} />
                <Route path = "/configurari"  component = {Configurari} />
                </Switch>
            </Layout>
        // </div>
   
   
    )
    
}

export default Main