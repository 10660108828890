import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Context} from '../../components/Store'
import {useMutation} from '@apollo/client'
import {Grid, Toolbar, makeStyles, IconButton, Chip, Fab, Badge} from '@material-ui/core'
import RichInput from '../../components/RichInput'
import {FaCheck, FaCheckCircle, FaPlusCircle, FaTimesCircle} from 'react-icons/fa'
import { Alert } from '@material-ui/lab'
import Confirm from '../../components/Confirm'
import {PROCEDURA_MUTATION, PROCEDURA_REMOVE_MUTATION} from '../../apollo/mutation'

  
const useStyles = makeStyles(theme=>({
    toolbar: {
        background: theme.palette.grey[200], fontWeight: 800, marginBottom: theme.spacing(2),
        display: "flex", justifyContent: "space-between",
        paddingRight: theme.spacing(0.5)
    },
    container: {padding: theme.spacing(1), border: "1px solid grey", maxHeight: "60vh", overflowY: "scroll"}
}))

export default function Proceduri(props) {

    const classes = useStyles()
    const [state, setState] = useContext(Context)
    const [proceduri, setProceduri] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [indexes, setIndexes] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [removeProcedura, dispRemove] = useMutation(PROCEDURA_REMOVE_MUTATION)
    const [submitProcedura, dispSubmit] = useMutation(PROCEDURA_MUTATION)

    useEffect(() => {
        if ( props && props.proceduri ) { setProceduri(props.proceduri)}
    }, [props])

    const inputHandler = (name, i) => ev => {
        let newProceduri = [...proceduri].map( el => ({...el}))
        newProceduri[i][name] = ev.target.value
        setProceduri(newProceduri)
        setCurrentIndex(i)
        if ( !indexes.includes(i)){ setIndexes([...indexes, i])}
    }

    const createItemHandler = () => {
        let newItem = {id: null, company_id: state.company_id, name: "", desc: ""}
        let newProceduri = [...proceduri]
        newProceduri.push(newItem)
        setIndexes([...indexes, proceduri.length])
        setProceduri(newProceduri)
    }

    const submitHandler = (e,i) => () => {
        let elem = {
            id: e.id,
            company_id: state.current_company_id,
            name: e.name,
            props: e.props
        }
        submitProcedura({variables: { data: elem }})
            .then( 
                r => {
                    let ix = [...indexes]
                    ix.splice(i,1)
                    setIndexes(ix)
                    props.onSubmit(true)
                },
                err => console.log("clasif create error:", err)
            )
    }

    const removeOpenConfirmHandler = (e,i) => () => {
        setCurrentIndex(i)
        setConfirm(true)
    }

    const removeHandler = ev => {
        if (ev){

         if (proceduri[currentIndex]['id']) {
             removeProcedura({variables: {id: proceduri[currentIndex]['id']}})
                .then(r => props.onSubmit(true), err => console.log("remove err:", err))
         } else {
             let newProceduri = [...proceduri]
             newProceduri.splice(currentIndex, 1)
             setProceduri(newProceduri)
             props.onSubmit(true)
         }
            
        }
        setCurrentIndex();setConfirm()
    }

    return (
        <div>
            <Toolbar variant = "dense" className = {classes.toolbar}>
                ADMINISTRARE PROCEDURI
                <div align = "right">
                    <IconButton size = "medium" color = "primary" onClick = {createItemHandler}>
                        <FaPlusCircle/>
                    </IconButton>
                </div>
            </Toolbar>
            <div className = {classes.container}>
               
                <Grid container  spacing = {1}>
                    {proceduri && proceduri.map( (e,i) => (
                        <Grid item xs = {6} key = {i}>
                            <RichInput 
                                value = {e.name} 
                                onChange = {inputHandler('name', i)}
                                start = {<div>{i+1}. &nbsp;</div>}
                                end = {
                                    <Grid >
                                        <IconButton size = "small" color = "secondary" onClick = {removeOpenConfirmHandler(e,i)}>
                                            <FaTimesCircle/>
                                        </IconButton> &nbsp;
                                        <IconButton
                                            onClick = {submitHandler(e,i)} 
                                            size = "small" color = "primary" disabled = {!indexes.includes(i)}
                                        >
                                            <FaCheckCircle/>
                                        </IconButton>
                                    </Grid>
                                }
                            />
                        </Grid>
                    ))}
                     
                </Grid>
            </div>
            {/* {proceduri && <pre>{JSON.stringify(proceduri, null, 4)}</pre>} */}
            <Confirm open = {confirm} onClose = {removeHandler} />
        </div>
    )
}
