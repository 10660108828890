import { useEffect, useState, useContext } from 'react'
import { Grid, Card, CardContent, CardActions, TextField, Button, CardActionArea, CardHeader, Typography } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import storage from '../lib/storage'
import { GoogleAuth, LinkedInAuth, FacebookAuth } from 'react-social-auth'
import { FaGoogle, FaFacebook, FaKey, FaLockOpen } from 'react-icons/fa'
import background from '../assets/background.png'
import lock from '../assets/keys.png'
import { useLazyQuery, gql } from '@apollo/client'
import {Context} from '../components/Store'


const LOGIN_QUERY = gql`query($data: LoginInput) {
 login(data: $data) {
  id name username email role token company_id department_id 
}
}
`

const Login = props => {

    const History = useHistory()
    const [state, dispatch] = useContext(Context)
    const [login, setLogin] = useState({ username: "", password: "" })

    useEffect(() => {
        console.log("login/props: ", props)
        if (state.token && props.location.pathname !== "/login") {
            //check token, if token invalid, do login, else go to /
            History.push('/')
        } else {

        }
    }, [])

    const [submit, dispatchSubmit] = useLazyQuery(LOGIN_QUERY)
    const [error, setError] = useState()
    
    useEffect(() => {
        const {data, error} = dispatchSubmit
        if ( dispatchSubmit.data)  {
            if ( dispatchSubmit.data && dispatchSubmit.data.login ) {
                storage.write(dispatchSubmit.data.login)
                dispatch(dispatchSubmit.data.login)
                History.push("/")
            }
        } 
            // console.log(dispatchSubmit, "ddd")
            setError(dispatchSubmit.error)
        
    },[{...dispatchSubmit}])

    const inputHandler = name => ev => setLogin({ ...login, [name]: ev.target.value })

    return (

        <Grid container justify="center" alignItems="center" style={{ height: "100%", background: `url(${background})` }}>
            <Grid item lg={4} sm={6}>
                <Card>

                    <CardContent style = {{background: `url(${lock})`, backgroundPositionX :  'right', backgroundPositionY:"top", backgroundRepeat: "no-repeat"}}>
                        <Grid container justify="center" spacing={1}>
                            <Grid item xs = {12} align = "center">
                            {/* <img src = {lock} style = {{width: '70px', height: "auto"}} /> */}
                         
                            </Grid>
                            <Grid item xs = {12} align = "center">
                                <Typography 
                                    align="center" variant="h3" 
                                    style = {{fontWeight: 800, color: "navy", textShadow: "1px 1px 5px lightblue"}}>
                                        AQUIS.RO
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="center">
                                managementul achizițiilor publice
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth variant="outlined"
                                    value={login.username}
                                    onChange={inputHandler('username')}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth variant="outlined"
                                    onKeyUp = { ev => ev.keyCode === 13 && submit({variables: {data:login}})}
                                    value={login.password} onChange={inputHandler('password')} fullWidth />
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Button
                                    size="large" variant="contained" color="primary" fullWidth
                                    onClick={() => submit({ variables: { data: login } })}
                                >
                                    AUTENTIFICARE
                                </Button>
                            </Grid>
                            <Grid item xs={12} align="center">
                                sau folosește un cont social (work in progress)
                            </Grid>
                            <Grid item xs={10} sm={10} md = {5} lg = {5}>
                                <Button variant="outlined" fullWidth size="large">
                                    <FaGoogle style={{ fontSize: "2rem", color: "darkred" }} /> &nbsp;&nbsp; GOOGLE
                                </Button>
                            </Grid>
                            <Grid item xs={10} sm={10} md = {5} lg = {5}>
                                <Button variant="outlined" fullWidth size="large">
                                    <FaFacebook style={{ fontSize: "2rem", color: "navy" }} /> &nbsp; FACEBOOK
                                </Button>
                            </Grid>
                            <Grid item xs = {10}>
                                {dispatchSubmit.error && (
                                    <pre style = {{color: "darkred"}}>
                                        {dispatchSubmit.error.toString()}
                                    </pre>
                                )}
                                {dispatchSubmit.data && <pre>{JSON.stringify(dispatchSubmit.data,null,4)}</pre>}
                            </Grid>

                        </Grid>

                    </CardContent>




                    <br />
                </Card>
            </Grid>

        </Grid>

    )
}

export default Login