export const referatInterface = state => ({
    user_id: state.id,
    company_id: state.company_id,
    department_id: state.department_id,
    company: {}, 
    department: {}, 
    nrdoc: "",
    datadoc: new Date(),
    necesitate:"",
    user: state, 
    status: "initiat",
    products: []
})

export const programInterface = {
    id: null,
    name: "",
    datadoc: new Date(),
    nrdoc: "",
    items: [],
    ordering: 0,
    referate: []
}

export const productInterface = {      
    id: null,
    nrdoc: "",
    datadoc: new Date(),
    company_id: null,
    department_id: null,
    user_id: null,
    ref_id: null,
    name: null,
    price: 0,
    vat: 0,
    amount: 0,
    unit: 'BUC',
    duedate: new Date(),
    start: new Date(),
    end: new Date(),
    obs: null,
    cpv_id: null,
    clasif_id: 5,
    artbug: null,
    procedura_id: null,
    sursa: "BUGET LOCAL",
    props: null,
    procedura_id: 1,
    cpv: {
        id: 258,
        version: null,
        code: "09132000-3",
        short: "BENZINA",
        long: null
    },
    procedura:{
        
    }
}
