import { Alert } from '@material-ui/lab'
import React from 'react'

export default function SettingsLanding() {
    return (
        <Alert severity = "info">
            Alegeți o categorie de configurări din meniul din stânga
        </Alert>
    )
}
