const env = process.env.NODE_ENV

const config = {
    development: {
        NAMESPACE: "AQUIS-DEV-V2",
        API_URL: "http://localhost:3300/graphql",

    },
    production: {
        NAMESPACE: "AQUIS-PROD-V2",
        API_URL: "https://api.aquis.ro/graphql"
    }
}

export default config[env]