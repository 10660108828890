import { Tab, Tabs, Box, Typography, Button, Paper } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react'
import ExportDate from './ExportDate'
import ImportDate from './ImportDate'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Paper style = {{marginTop: "5px"}}>
            <Typography component = "div">{children}</Typography>
          </Paper>
        )}
      </div>
    );
  }
export default function Backup() {
    const [index, setIndex] = useState(0)

    const tabHandler = (ev, val) => setIndex(val)

    return (
        <div>

            <Tabs value={index} onChange={tabHandler}>
                <Tab label="BACKUP DATE" value = {0}/>
                <Tab label="RESTAURARE" value ={1}/>

            </Tabs>
            <TabPanel value={index} index = {0}>
                <ExportDate/>

            </TabPanel>
            <TabPanel value={index} index = {1}>
               <ImportDate/>
            </TabPanel>
            <Alert severity = "warning">EXPERIMENTAL. ÎN DEZVOLTARE! </Alert>
        </div>
    )
}
