import config from '../config'

const { NAMESPACE } = config

const isJSON = str => {
    try {  JSON.parse(str) } 
    catch (e) { return false }
    return true;
}

export const get = () => {
    let ls = localStorage.getItem(NAMESPACE) 
     ls = isJSON(ls) && ls !== null ? JSON.parse(ls) : {}
     return (ls)
}

export const write = item => {
    let oldLs = get()
    item = typeof item !== "object" ? isJSON(item) : item
    if ( item ) {
        return localStorage.setItem(NAMESPACE, JSON.stringify({...oldLs, ...item}))
    }
}

export const remove = () => localStorage.removeItem(NAMESPACE)

export default {get, write, remove}