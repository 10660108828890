import {useState, useEffect, Fragment} from 'react'
import { useQuery } from '@apollo/client'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Toolbar, makeStyles, 
    Grid,IconButton, MenuItem, Checkbox, Card, TextField} from "@material-ui/core"
import moment from 'moment'
import { FaCheckDouble, FaTimesCircle } from 'react-icons/fa'
import {REFERATE_QUERY} from '../../apollo/query'
import ReactTable7 from '../../components/ReactTable7'
import { totalReferat } from '../../lib/compute'

const useStyles = makeStyles(theme => ({
    dialogPaper: { minWidth: "90vw", minHeight: "90vh"},
    toolbar: {background: theme.palette.grey[200], display:"flex", alignItems:"center", justifyContent: "space-between"},
    card: { background: theme.palette.grey[100], padding: theme.spacing(1)  },
        table: {
        tableLayout: "auto",
        width: "100%",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid grey",
            background: theme.palette.grey[200],
            wordWrap: "normal",
            fontSize: "0.7rem",
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
        },
        '&& tbody>tr>td' : {
            wordWrap: "break-word"
        },
    }
}))

const now = moment(new Date())

const AsociereRefeateModal = props => {
    const classes = useStyles()
    const [program, setProgram] = useState()
    
    const [ start, setStart ] = useState(moment(now.format('Y')+"-01-01").toDate())
    const [ end  , setEnd ] = useState(moment(now.format('Y')+"-12-31").toDate())

    const {data, error} = useQuery(REFERATE_QUERY, {variables: {
        data: {
            "like": [
                {
                    key: "status",
                    value: "aprobat"
                }
            ],
            "chunk": {
                "offset": 0,
                "limit": 100000
            },
            "ordering": {
                "row": "id",
                "order": "asc"
            },
            between: {
                row: "datadoc",
                start: start,
                end: end 
            }
        }
    }})

    useEffect(()=>{
        setProgram(props.data)
    }, [props.data])



    const closeHandler = name => ev => {
        console.log("name:::", name)
        props.onClose(name)
    }

    const isSelected = (el, program) => {
        const selected = [...program.referate].map( el => parseInt(el.id))
        return selected.filter( elem => elem === parseInt(el)).length > 0 ? true : false
    }

    const toggleHandler = (program, item) => () => {
        let newProg = {...program, referate: [...program.referate], items: [...program.items]}
        let newProgElem = newProg.referate.filter( elem => elem.id === item.id )
        if ( newProgElem.length > 0 ) {
            newProg.referate.splice(newProg.referate.indexOf(newProgElem[0]),1)
        } else {
            newProg.referate.push(item)
        }
        newProg.items = newProg.referate.map( el => el.id.toString())
        setProgram(newProg)
    }

    const toggleAllHandler = (referate, program) => () => {
        let newProg = {...program, referate: [...program.referate]}
        if ( program.referate.length < 1 ) {
            newProg.referate = [...referate]
            newProg.items = [...referate].map( el => el.id.toString())
        } else {
            newProg.referate = []; newProg.items = []
        }
        console.log("toggle all:", newProg.items)
        setProgram(newProg)
    }

    const dateFilterHandler = name => ev => {
        if ( name === "start") { setStart(moment(ev.target.value).toDate())}
        if ( name === "end") { setEnd(moment(ev.target.value).toDate())}
    }

    return (
        <Dialog open = {Boolean(program)} classes = {{paper: classes.dialogPaper}}onClose = {closeHandler()}>
            {/* {console.log("data:", data)} */}
            <DialogTitle style = {{padding: 0}}>
                <Toolbar className = {classes.toolbar}>
                    <div>
                Asociere referate pentru programul nr. 
                {program && program.nrdoc} / {program && moment(program.datadoc).format('DD.MM.YYYY')}
                </div>
                <div>
                    <IconButton onClick = {closeHandler()}>
                        <FaTimesCircle/>
                    </IconButton>
                </div>
                </Toolbar>
               
            </DialogTitle>
            <DialogContent>
                
                <Card className = {classes.card}>
                <Grid container>
                    <Grid item xs = {3}>
                            <TextField 
                                variant = "outlined" size = "small" type = "date" label = "data start"
                                value ={moment(start).format('YYYY-MM-DD')}  
                                onChange = {dateFilterHandler('start')}
                            />
                    </Grid>
                    <Grid item xs = {3}>
                            <TextField 
                                variant = "outlined" size = "small" type = "date" label = "data final"
                                value ={moment(end).format('YYYY-MM-DD')}  
                                onChange = {dateFilterHandler('end')}
                            />
                    </Grid>
                    </Grid>
                </Card>
                

                { program && data && data.referate 
                 && <ReactTable7 
                    data = {data.referate.map( el => ({
                        ...el,
                        isSelected: isSelected(el.id,program),
                        dtRef: moment(el.datadoc).format('DD.MM.YYYY'),
                        toggler: (<Checkbox 
                            value = {isSelected(el.id, program)} 
                            checked = {isSelected(el.id, program)} 
                            onClick = {toggleHandler(program, el)}
                        />),
                        total: totalReferat(el)
                    }))}
                    disablePagination = {true}
                    defaultPageSize = "999999999"
                    style = {{height: "60vh", overflow: "auto"}}
                    columns = {[
                        {
                            Header: (
                                <IconButton onClick = {toggleAllHandler(data.referate, program)}>
                                    <FaCheckDouble />
                                </IconButton>
                            ),
                            id: "toggle",
                            Cell: ({row}) => row.original.toggler

                        },
                        {
                            Header: "Nr.doc",
                            accessor: "nrdoc",
                            Cell: ({row}) => (<div align ="center">{row.original.nrdoc}</div>)
                        },
                        {
                            Header: "Data doc.", accessor: "dtDoc"
                        },
                        {
                            Header: "Descriere",
                            accessor: "necesitate"
                        },
                        {
                            Header: "Total referat",
                            accessor: "total",
                            Cell: ({row}) => <div align = "right">{row.original.total}</div>
                        }
                        
                    ]}
                 
                 />}
                
                {/* {program && (<pre>{JSON.stringify( program, null, 4 )}</pre>)} */}
            </DialogContent>
            <DialogActions>
                <Button 
                    variant = "contained" color ="secondary" size ="small"
                    onClick = {closeHandler()}
                >
                    RENUNȚĂ
                </Button>
                <Button 
                    variant = "contained" color ="primary" size ="small"
                    onClick = {closeHandler(program)}
                >
                    CONFIRMĂ
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AsociereRefeateModal