import { Card, Divider, Grid, MenuItem, makeStyles, TextField, Typography,  } from "@material-ui/core"
import {useContext, useState, useEffect, Fragment} from 'react'
import { useQuery } from '@apollo/client'
import { COMPANIES } from '../../apollo/query'
import { Route } from "react-router"
import Container from "../../components/Container"
import Unitate from './Unitate'
import Users from './Users'
import Proceduri from './Proceduri'
import Clasificatie from './Clasificatie'
import Cpv from './Cpv'
import SettingsLanding from './SettingsLanding'
import Departamente from "./Departamente"
import { Context } from '../../components/Store'
import { NetworkStatus } from '@apollo/client';
import Surse from "./Surse"
import Backup from "./Backup"


const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(1), height: "75vh", overflow: "auto"
    }
}))

const MENU = [
    { name: "UNITATE", path: "/configurari/unitate" },
    { name: "DEPARTAMENTE", path: "/configurari/departamente" },
    { name: "UTILIZATORI", path: "/configurari/utilizatori" },
    { name: "SURSE FINANȚARE", path: "/configurari/surse" },
    { name: "CLASIFICAȚIE", path: "/configurari/clasificatie" },
    { name: "PROCEDURI", path: "/configurari/proceduri" },
    { name: "CODURI CPV", path: "/configurari/cpv" },
    { name: "BACKUP/RESTORE", path: "/configurari/backup" },
]

const Configurari = props => {
    const classes = useStyles()
    const [state, setState] = useContext(Context)
    const { data, loading, refetch, networkStatus } = useQuery(COMPANIES, { notifyOnNetworkStatusChange: true })
    const [companyId, setCompanyId] = useState()
    const [currentCompany, setCurrentCompany] = useState()

    useEffect(() => {
        console.log("S:", state.current_company_id)
        if (typeof state.current_company_id !== "undefined") {
            setCompanyId(state.current_company_id)
        } else {
            if( data && data.companies ) {
                setCompanyId(data.companies[0]['id'])
                setCurrentCompany(data.companies[0])
            }
        }
        
    }, [state, data])

    useEffect(() => {
        if ( data && data.companies) {
            if (typeof state.current_company_id !== "undefined") {
                setCurrentCompany(data.companies.filter( elem => elem.id === state.current_company_id)[0])
            } else {
                setState({current_company_id: data.companies[0]['id']})
            }
        }
    }, [ data ])

    const selectCompanyHandler = ev => {
        
        // setCompanyId(ev.target.value)
        setState({current_company_id:ev.target.value})
        setCurrentCompany(data.companies.filter( elem => elem.id === ev.target.value)[0] )
    }

    const clickHandler = path => () => props.history.push(path)

    const submitedHandler = ev => { console.log("submitted in index:", ev); refetch() }
     
    return (
        <Container
            title="CONFIGURĂRI"
            action = {
                   <div style = {{marginRight: "10px"}}>
                        {data && (
                            <TextField
                                style = {{background: "white"}}
                                variant="outlined" size="small" select fullWidth
                                value={companyId || 0}
                                onChange = {selectCompanyHandler}
                            >
                                {data.companies.map( (e,i) => (
                                    <MenuItem key = {i} value = {e.id}> {e.name}</MenuItem>
                                ))}
                                
                            </TextField>
                            
                        )}
                       
                    </div>
                   
                

            }
        >
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={2}>
                    <Card className={classes.card}>
                        {MENU.map((e, i) => (
                            <Fragment key={i}>
                                <MenuItem onClick={clickHandler(e.path)}>
                                    <Typography variant = "caption">{e.name}</Typography>
                                    </MenuItem>
                                <Divider />
                            </Fragment>
                        ))}
                    </Card>
                </Grid>
                <Grid item xs={10}>
                    <Card className={classes.card}>
                        <Route path="/configurari" exact component={props => <SettingsLanding />} />
                        <Route
                            path="/configurari/unitate" 
                            component={props => <Unitate {...{...props, company: currentCompany}} onSubmit = {submitedHandler} />}
                        />
                        <Route 
                            path="/configurari/departamente" 
                            component={props => <Departamente {...{...props, ...currentCompany}}  onSubmit = {submitedHandler}/>} 
                        />
                        <Route 
                            path="/configurari/utilizatori" 
                            component={props => <Users {...{...props, ...currentCompany}}  onSubmit = {submitedHandler}/>} 
                        />

                        <Route path="/configurari/surse" 
                            component={props => <Surse {...{...props, ...currentCompany}} onSubmit = {submitedHandler}/>} 
                        />

                        <Route path="/configurari/clasificatie" 
                            component={props => <Clasificatie {...{...props, ...currentCompany}} onSubmit = {submitedHandler}/>} 
                        />
                        
                        <Route path="/configurari/proceduri" 
                            component={props => <Proceduri {...{...props, ...currentCompany}}  onSubmit = {submitedHandler}/>}
                        />

                        <Route path="/configurari/cpv" component={Cpv} />
                        <Route path="/configurari/backup" component={Backup} />
                    </Card>
                </Grid>

            </Grid>
        </Container>
    )
}

export default Configurari