import { Fragment, useState, useEffect, useContext } from 'react'
import { Context } from '../../components/Store'
import { Divider, Grid, IconButton, MenuItem, Typography, Tooltip, Button } from "@material-ui/core"
import { FaListAlt, FaPlusCircle, FaTimesCircle } from "react-icons/fa"
import Container from "../../components/Container"
import RichCard from "../../components/RichCard"
import { useQuery, useMutation } from "@apollo/client"
import { PROGRAME } from '../../apollo/query'
import { PROGRAM_MUTATION } from '../../apollo/mutation'
import moment from 'moment'
import { Alert } from '@material-ui/lab'
import ProgramModal from './ProgramModal'
import AsociereReferateModal from './AsociereReferateModal'
import { programInterface } from '../../lib/inferfaces'
import { totalReferat } from '../../lib/compute'

const Programe = props => {
    const [state, setState ] = useContext(Context)
    const { data, loading, error, refetch } = useQuery(PROGRAME)
    const [program, setProgram] = useState()
    const [currentProgramIndex, setCurrentProgramIndex] = useState()
    const [programe, setPrograme] = useState([])
    const [programModal, setProgramModal] = useState()
    const [referateModal, setReferateModal] = useState()
    const [submitProgram, dispatchSubmitProgram ] = useMutation(PROGRAM_MUTATION)

    useEffect(()=>{
        if ( data && data.programe ) {setPrograme(data.programe) }
    }, [data])


    const programHandler = (prog,i) => () => { setCurrentProgramIndex(i); setProgram(prog) }

    const programModalHandler = (ev, removed) => {
        // console.log("program modal close:",ev)
        if (ev) { 
            setProgram(ev)
            let newPrograme = [...programe]
            if ( ev.id) {
                newPrograme[currentProgramIndex] = ev
                setPrograme(newPrograme)
                setProgram(newPrograme[currentProgramIndex])
                
            } else {
                newPrograme.push(ev)
                setPrograme(newPrograme)
                setProgram(ev)
            }
        }
        if ( removed ){
            let p = [...programe]
            p.splice(currentProgramIndex,1)
            setPrograme(p)
            setProgram()
            setCurrentProgramIndex()
        }
        setCurrentProgramIndex()
        setProgramModal()
        
    }

    const removeReferatHandler = i => () => {
        let p = { ...program, referate: [...program.referate] }
        p.referate.splice(i, 1)
        p.items = p.referate.map( el => el.id)
        setProgram(p)
    }

    const referateModalHandler = ev => {
        // console.log("close ev:", ev)
        if ( ev ) setProgram({...ev})
        setReferateModal()
    }

    const submitHandler = () => {
        // console.log("program:", program)
        let query = {
            id: program.id,
            company_id: program.company_id,
            name: program.name || "",
            datadoc: program.datadoc || new Date(),
            nrdoc: program.nrdoc || "",
            items: program.items.map( el => parseInt(el))
        }
        console.log("QUERY:", query)
        submitProgram({variables: { data: query }}).then( 
            r => { if (r && r.data && r.data.program) { refetch().then(
                res => { if (res.data && res.data.programe) { 
                    setPrograme(r.data.programe)}
                    setState({notification: true})
                }
            )} },
            err => console.log("err:", err)
        )
    }

    // useEffect(()=>{
    //     if ( dispatchSubmitProgram.data ) { 
    //             // console.log("refetching...."); 
    //         refetch().then(r=>{
    //             if (r.data && r.data.programe) { setPrograme(r.data.programe)}
    //         })
    //     }
    // }, [dispatchSubmitProgram.data])

    return (
        <Container
            title="Programe anuale de achiziți"
            action={
                <Button
                    style = {{marginRight: "10px"}}
                    variant = "contained" 
                    color = "secondary"
                    disabled = {!program}
                    onClick = {submitHandler}
                >
                    CONFIRMĂ MODIFICĂRILE
                </Button>
            }>
                {/* {console.log("data:", data, loading, error)} */}
            {programe && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} lg={3}>
                        <RichCard 
                            title="Programe" action={
                                <IconButton 
                                    variant="small" color="primary"  
                                    onClick = {()=>setProgramModal({...programInterface, company_id: state.company_id})}>
                                    <FaPlusCircle />
                                </IconButton>
                            }
                            footer = {
                                <Alert severity = "info" >
                                    <Typography variant = "caption">
                                        click dreapta pentru editare/ștergere
                                    </Typography>
                                </Alert>
                            }
                            >
                            { programe.map((e, i) => (
                                <Fragment key={i}>
                                    <MenuItem 
                                        style = {currentProgramIndex === i ? {background: "lightpink"}: null}
                                        onClick={programHandler(e,i)} 
                                        onContextMenu = {ev => {ev.preventDefault(); setProgramModal(e); setCurrentProgramIndex(i);}}>
                                        <Typography variant="caption">
                                            <strong>{e.name} </strong><br />
                                            (nr. {e.nrdoc} / {moment(e.datadoc).format('DD.MM.YYYY')})

                                            </Typography>
                                    </MenuItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </RichCard>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9}>
                        <RichCard
                            title={program && "Referate asociate programului selectat"}
                            action={
                                program && 
                                <IconButton variant="small" color="primary" onClick = {() => setReferateModal(program)}>
                                    <FaListAlt />
                                </IconButton>
                            }
                        >
                            {program && program.referate
                                ? (program && program.referate.map((e, i) => (
                                    <Fragment key={i}>
                                        <div style = {{whiteSpace: "wrap"}}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        size="small" color="secondary"
                                                        onClick={removeReferatHandler(i)}
                                                    >
                                                        <FaTimesCircle />
                                                    </IconButton>
                                                </Grid>
                                                <Tooltip title={e.necesitate} placement="bottom">
                                                    <Grid item xs={8}>
                                                        <Typography variant="caption" color="primary">
                                                            <strong>
                                                                Referat nr. {e.nrdoc} / {moment(e.datadoc).format('DD.MM.YYYY')}
                                                            </strong>
                                                            &nbsp;
                                                            ({e.user.name} / {e.department.name} /{e.company.name})
                                                            <br />
                                                            {e.necesitate.slice(0, 50)}
                                                            {e.necesitate.length > 50 ? "...." : ""}
                                                        </Typography>
                                                    </Grid>
                                                </Tooltip>
                                                <Grid item xs ={3} align = "right">
                                                    {totalReferat(e)}
                                                </Grid>
                                            </Grid>

                                        </div>
                                        <Divider />
                                    </Fragment>
                                )))
                                : <Alert severity = "warning">
                                    Alegeți sau adăugați un program
                                    </Alert>
                            }
                        </RichCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="success">
                            Pentru a putea genera programul anual de achiziții în vederea listării, este necesar să asociem referatele aprobate
                                entităților numite de noi "programe". <br />
                                Prcedând în acest mod veți putea genera un număr nelimitat de programe de achiziții, grupate după orice criterii
                        </Alert>

                    </Grid>
                </Grid>
            )}

            <ProgramModal data = {programModal} onClose = {programModalHandler} />
            <AsociereReferateModal data = {referateModal} onClose = {referateModalHandler} />
        </Container>
    )
}

export default Programe