import { Autocomplete } from "@material-ui/lab"
import {useQuery} from '@apollo/client'
import {  CPV } from '../../apollo/query'
import { Fragment, useState, useEffect } from "react"
import { TextField, makeStyles } from "@material-ui/core"
import {useDebounce} from '../../lib/reactHooks'

const useStyles = makeStyles(theme => ({
    input: { fontWeight: 800,  color: theme.palette.primary.dark},
    textField: { background: "rgba(63,81,181,0.07)", border: "none" ,fontWeight: 800,color: theme.palette.primary.dark},
}))



const CpvSelect = props => {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [searchString, setSearchString] = useState()
    const debounced = useDebounce(searchString, 500)

    const {data, loading, error } = useQuery(CPV, {variables: {
        data: {
            like: [
                {key: "code", value: debounced},
                {key:"short", value:debounced}
            ]
        }
    }})

    useEffect(()=>{
        setItem(props.data)
    }, [props])



    const changeHandler = (ev, val, reason) => {
        if (reason === "clear"){
            props.onChange({...item, cpv_id: "", cpv:{}})
        }
        if (val && reason === "select-option") {
            props.onChange({...item, cpv_id: val.id, cpv: val})
        }  
    }

    return (
        <Fragment>
            {item  && (
                <Autocomplete
                    fullWidth variant = "outlined" size = "small" 
                    name = "codcpv" required
                    label = "cod cpv" 
                    value = {item.cpv}  
                    options = {data && data.cpvs || []}
                    // getOptionSelected = {(opt,val) =>  console.log("ooo:",opt.id === val.toString() ? true : false)}
                    getOptionLabel={(option) => option.code ? option.code +' - '+ option.short : ""}
                    renderInput={(params) => <TextField 
                        {...params} 
                        label="Cod C.P.V." 
                        variant="outlined" 
                        // InputProps = {{className: classes.input}} 
                        classes = {{root: classes.textField}} 
                        style = {{fontWeight: 800}}
                    />}
                    noOptionsText = "nu există"  
                    onInputChange = {(__,value, reason) => setSearchString(value|| "xxxxxxx")}
                    onChange = {changeHandler}
                />
            )}

        </Fragment>
    )
}

export default CpvSelect