export const rowTotal = (item, hasVat) => {
    let price, amount, vat
    if ( item ) {
        price = typeof parseInt(item.price) === "number" ? parseFloat(item.price) : 0
        amount = typeof parseInt(item.amount) === "number" ? parseFloat(item.amount): 1
        vat  = typeof parseInt(item.vat) === "number" ? item.vat: 1
        let res =  price * amount * (hasVat ? 1+(vat/100) : 1)
        return res
    } else {
        return 0
    }
}

export const totalReferat = (referat) => {
    let products = referat && referat.products ? [...referat.products] : []
    products = products.reduce( (acc,item) => acc + rowTotal(item), 0)
    return Math.round(products*100)/100..toFixed(2)
   
}