import { gql } from '@apollo/client'

export const APP_MUTATION = gql`mutation createOrUpdateApp($data: AppInput ){
    createOrUpdateApp(data:$data){
        id code name desc url allowed_roles
    }
}`

export const APP_REMOVE_MUTATION = gql`mutation removeApp($id:ID){
    removeApp(id:$id)
}`



export const REFERAT_MUTATION = gql`
mutation referat( $data: ReferatMutateInput) {
  referat(data:$data) {
    id nrdoc datadoc necesitate status necesitate company_id department_id user_id products {id company_id department_id user_id name price vat amount unit duedate start end cpv_id clasif_id artbug procedura_id
    cpv { id code short long }
     procedura { id name }
    }
  }
}`

export const REFERAT_REMOVE_MUTATION = gql`
    mutation referatRemove($id: ID) {
        referatRemove(id:$id)
    }
`

export const PROGRAM_MUTATION = gql`
    mutation program($data: ProgramInput) {
    program(data: $data) 
}
`

export const PROGRAM_REMOVE_MUTATION = gql`
    mutation programRemove($id: ID) {
    programRemove(id: $id) 
}
`

export const COMPANY_MUTATION = gql`
    mutation company($data: CompanyInput) {
        company(data:$data) { id name props}
    }
`
export const COMPANY_REMOVE_MUTATION = gql`mutation companyRemove($id:ID){
    companyRemove(id:$id)
}`

export const DEPARTMENT_MUTATION = gql`
    mutation department($data: DepartmentInput) {
        department(data: $data) { id name }
    }
`
export const DEPARTMENT_REMOVE_MUTATION = gql`mutation departmentRemove($id:ID){
    departmentRemove(id:$id)
}`

export const USER_MUTATION = gql`mutation user($data: UserInput) {
    user(data:$data){
      id name username email password company_id department_id
    }
  }`

  export const USER_REMOVE_MUTATION = gql`mutation userRemove($id:ID){
      userRemove(id:$id)
  }`

export const CLASIF_MUTATION = gql`mutation clasificatie($data:ClasifInput) {
    clasificatie(data:$data) { id company_id name ordering props }
}`

export const CLASIF_REMOVE_MUTATION = gql`mutation clasificatieRemove($id:ID) {
    clasificatieRemove(id:$id)
}`

export const PROCEDURA_MUTATION = gql`mutation procedura($data:ProceduraInput) {
    procedura(data:$data) { id company_id name desc }
}`

export const PROCEDURA_REMOVE_MUTATION = gql`mutation proceduraRemove($id:ID) {
    proceduraRemove(id:$id)
}`

export const SURSA_MUTATION = gql`mutation sursa($data:SursaInput) {
    sursa(data:$data) { id company_id name desc }
}`

export const SURSA_REMOVE_MUTATION = gql`mutation proceduraRemove($id:ID) {
    sursaRemove(id:$id)
}`

