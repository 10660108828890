

import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Button, Grid, IconButton, makeStyles, MenuItem, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Typography } from "@material-ui/core"
import { Fragment, useContext, useEffect, useState } from 'react'
import { FaCheck, FaPlusCircle, FaPrint, FaUserEdit } from 'react-icons/fa'
import { REFERAT_QUERY, COMPANIES } from '../../apollo/query'
import { REFERAT_MUTATION } from '../../apollo/mutation'
import Container from '../../components/Container'
import RichCard from '../../components/RichCard'
import { Context } from '../../components/Store'
import ProductModal from './ProductModal'
import { productInterface, referatInterface } from '../../lib/inferfaces'
import PrintModal from './PrintModal'
import moment from 'moment'
import Confirm from '../../components/Confirm'
import { Alert } from '@material-ui/lab'


const statuses = [
    { id: 0, name: "initiat" },
    { id: 1, name: "trimis" },
    { id: 2, name: "aprobat" },
    { id: 3, name: "respins" },
    { id: 4, name: "anulat" }
]

const twoDecimals = num => Math.round((num + Number.EPSILON) * 100) / 100

const getStatusDisablement =(role, stats, item, index, currentStatus) => {
    let result = true;
    switch (role) {
        case "app" : 
            result = false
            break;
        case "admin": 
            result = false
            break;
        default: 
             result = false
            if ( ['aprobat', 'respins', 'anulat'].includes(item.name)) { result = true }
            if ( currentStatus === "aprobat" ) { result = true}
            break;
    }
    return result
}

const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1 },
    container: {},
    paper: {
        background: theme.palette.grey[200]
    },
    table: {
        // border: "1px solid grey",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid grey",
            background: "rgba(63,81,181,0.04)",
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
            padding: theme.spacing(1)
        },

        '&& tbody>tr:hover': {
            background: theme.palette.grey[400],
            cursor: "zoom-in",
            border: "2px solid navy",
        },
    }
}))

const Referat = props => {
    const [state, setState] = useContext(Context)
    const classes = useStyles()
    const [refId, setRefId] = useState()
    const [item, setItem] = useState()
    const { data, error, refetch } = useQuery(REFERAT_QUERY, { variables: { id: refId } })
    const [productModal, setProductModal] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [rowsToRemoveOnSubmit, setRowsToRemoveOnSubmit] = useState([])
    const [userModal, setUserModal] = useState()
    const [printModal, setPrintModal] = useState()
    const [confirmModal, setConfirmModal] = useState()
    const [ submitReferat, dispatchSubmitReferat ] = useMutation(REFERAT_MUTATION)
    const [ err, setErr ] = useState()
    const [ companies, setCompanies ] = useState()
    const [ getCompanies, dispatchCompanies ] = useLazyQuery(COMPANIES)
    const [notification, setNotification] = useState()

    

    useEffect(() => {
        if ( state && ['app', 'admin'].includes(state.role)) { getCompanies() }
        if (props.match && props.match.params && props.match.params.id === "nou") {
            setItem(referatInterface(state))
        } else {
            setRefId(props.match.params.id)
        }
    }, [state.id])



    useEffect(() => {
        if (data && data.referat) {
            console.log("referat", data.referat)
            let d = { ...data.referat }
            setItem(d)
        }
    }, [data])

    const isDisabled = () => {
        return !['app', 'admin'].includes(state.role) && !["initiat", "trimis"].includes(item.status)
    }

    

    useEffect(()=> {
        if (dispatchCompanies.data) setCompanies(dispatchCompanies.data.companies)
        
    },[dispatchCompanies.data])

    const getDepartments = (companies, item) => {
        let curCompany = companies.filter( elem => elem.id === item.company_id)[0] 
        return curCompany.departments
    }
    const getUsers = (companies, item) => {
        let curCompany = companies.filter( elem => elem.id === item.company_id)[0]
        let curDepartment = curCompany.departments.filter( elem => elem.id === item.department_id)[0]
        return curDepartment.users
    }
    // useEffect(()=> refetch(), [dispatchSubmitReferat.data])

    const calcTotal = (arr, hasTva) => {
        return arr
            ? arr.reduce((acc, item) => {
                acc = acc + parseFloat(item.amount) * parseFloat(item.price) * (hasTva ? 1 + item.vat / 100 : 1)
                return Math.round(acc*100)/100
            }, 0)
            : 0
    }

    

    const setProductModalOpenHandler = (e, i) => () => {
        setCurrentIndex(i)
        setProductModal(e)
    }
    const newProductHandler = () => {
        const prod = {
            ...productInterface,
            id: null,
            user_id: state.id,
            company_id: state.company_id,
            department_id: state.department_id
        }
        let newItem = { ...item, products: [...item.products, prod] }
        setItem(newItem)
    }

    const inputHandler = name => ev => setItem({ ...item, [name]: ev.target.value })
    const dateHandler = name => ev => setItem({ ...item, [name]: moment(ev.target.value).toDate() })

    const productModalCloseHandler = ev => {
        console.log("product:", ev)
        console.log("product item:", item)
        if (ev) {
            let newItem = { ...item, products: [...item.products] }
            newItem.products[currentIndex] = ev
            setItem(newItem)
        }
        setProductModal()
    }

    const rowContextHandler = (e, i) => ev => {
        ev.preventDefault()
        setCurrentIndex(i)
        setConfirmModal(true)
    }
    const removeHandler = ev => {

        if (ev) {
            let newItem = { ...item }
            let product = item.products[currentIndex]
            if (!product.id) { //no id, new row
                newItem.products.splice(currentIndex, 1)
                setItem(newItem)
            } else {        
                setRowsToRemoveOnSubmit([...rowsToRemoveOnSubmit, item.products[currentIndex]['id']])
            }
        }
        setConfirmModal(); setCurrentIndex()
    }

    const submitHandler = () => {
        console.log("submited item:", item)
        const qData = {
            referat: {
                id:item.id || null,
                nrdoc: item.nrdoc,
                datadoc: item.datadoc || new Date(),
                necesitate: item.necesitate,
                status: item.status,
                company_id: item.company_id,
                department_id: item.department_id,
                user_id: item.user_id,
            },
            products: item.products.map( product => ({
                id: product.id,
                company_id: product.company_id,
                department_id: product.department_id,
                user_id: product.user_id,
                name: product.name,
                price: parseFloat(product.price),
                vat: parseFloat(product.vat),
                amount: parseFloat(product.amount),
                unit: product.unit,
                duedate: product.duedate || new Date(),
                start:product.start || new Date(),
                end: product.end || new Date(),
                props: product.props,
                cpv_id: product.cpv_id,
                clasif_id: product.clasif_id,
                artbug: product.artbug,
                procedura_id: product.procedura_id,
                sursa_id: product.sursa_id
            })),
            rowsToRemove: rowsToRemoveOnSubmit
        }
        submitReferat({variables: {
            data: qData
        }}).then( 
            r => {
                    setState({notification: true})
                    setRefId(r.data.referat.id)
                    props.history.push('/referat/'+r.data.referat.id)
                    
            },
            err => setErr(err.toString())
        )
        console.log("submiting...", qData)
    }

    const getOutlineColor = (id) => {
        if ( !id ) { return "linnen"}
        else {
            // console.log("rowsTo:", rowsToRemoveOnSubmit,id)
            return rowsToRemoveOnSubmit.filter( el => el.toString() === id.toString()).length > 0 ? "red": "none"
        } 
    }


   
    return (
        <Fragment>
            {/* {console.log("item", state)} */}
            {/* {(error) && <pre>{error.toString()}</pre>} */}
            {(err) && <pre>{err.toString()}</pre>}
            {item && (
                <Container
                    action={
                        <div style={{ marginRight: "10px" }}>
                            <Button size="small" color="primary" variant="outlined"
                                disabled={!refId}
                                onClick={() => setPrintModal(item)}>
                                <FaPrint />&nbsp;&nbsp;
                            TIPĂREȘTE
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                                size="small" color="secondary" variant="outlined"
                                disabled={!(item.nrdoc && item.datadoc && item.necesitate && item.products.length > 0) || isDisabled()}
                                onClick = {submitHandler}
                            >
                                <FaCheck />&nbsp;&nbsp;
                            CONFIRMĂ
                        </Button>

                        </div>}
                    title={
                        <Grid container justify="flex-start" alignItems="center" spacing={1}>
                            <Typography variant="h5"><strong>Referat nr. </strong></Typography>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    style={{ background: "white" }}
                                    label="nr.document" placeholder="nr.doc" variant="outlined" size="small" fullWidth={true}
                                    value={item.nrdoc}
                                    onChange={inputHandler('nrdoc')}
                                />
                            </Grid>
                            <Typography variant="h5"><strong>din </strong></Typography>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    style={{ background: "white" }} type="date"
                                    label="data document" placeholder="nr.doc" variant="outlined" size="small" fullWidth={true}
                                    value={moment(item.datadoc).format('YYYY-MM-DD')}
                                    onChange={dateHandler('datadoc')}
                                />
                            </Grid>

                        </Grid>
                    }
                >

                    <Grid container justify="space-between" alignItems="flex-end" spacing={2}>
                        {console.log("item:", item && item.user_id)}
                        {companies  && (
                            <Grid item xs = {12}>
                                <Grid container spacing = {1}>
                                    <Grid item xs = {12} sm = {4}>
                                        <TextField 
                                            variant = "outlined" size = "small"  fullWidth select 
                                            label = "Unitatea"
                                            value = {item.company_id} 
                                            onChange = {inputHandler('company_id')}
                                            >
                                            {companies.map( (e,i) => (
                                                <MenuItem key = {i} value = {e.id}>
                                                    {e.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs = {12} sm = {4}>
                                        <TextField 
                                            variant = "outlined" size = "small" fullWidth select 
                                            label = "Compartimentul"
                                            value = {item.department_id} 
                                            onChange = { inputHandler('department_id')}
                                        >
                                            {getDepartments(companies,item).map( (e,i) => (
                                                <MenuItem key = {i} value = {e.id}>{e.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs = {12} sm = {4}>
                                        <TextField 
                                            variant = "outlined" size = "small" fullWidth select 
                                            label = "Titularul referatului"
                                            value = {item.user_id} 
                                            onChange = { inputHandler('user_id')}
                                        >
                                            {getUsers(companies,item).map( (e,i) => (
                                                <MenuItem key = {i} value = {e.id}>{e.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            {/* <RichCard title = "DESCRIERE NECESITATE PE SCURT" style = {{height: "15vh"}}> */}
                            <TextField variant="outlined" size="small" fullWidth label="DESCRIERE REFERAT"
                                value={item.necesitate}
                                onChange={inputHandler('necesitate')}


                            />
                            {/* </RichCard> */}
                        </Grid>
                        <Grid item xs={12}>
                            <RichCard
                                title="PRODUSE SAU SERVICII SOLICITATE" style={{ height: "55vh", padding: "5px" }}
                                action={<IconButton size="small" color="primary" onClick={newProductHandler}>
                                    <FaPlusCircle />&nbsp;<Typography variant = "caption">adaugă produs nou</Typography>
                                </IconButton>}
                            >
                                <div style={{ height: "47vh", overflow: "auto" }}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="5" align="center"> Nr.crt.</TableCell>
                                                <TableCell width="45" align="center"> Denumire produs/serviciu/lucrare</TableCell>
                                                <TableCell width="5" align="center"> C.P.V. </TableCell>
                                                <TableCell width="15" align="center">Procedura</TableCell>
                                                <TableCell width="5" align="center"> U.M.</TableCell>
                                                <TableCell width="5" align="center"> P.U.</TableCell>
                                                <TableCell width="5" align="center"> Cantit.</TableCell>
                                                <TableCell width="5" align="center"> TVA</TableCell>
                                                <TableCell width="10" align="center"> Total<br />(fără TVA)</TableCell>
                                                <TableCell width="10" align="center"> Total<br />(cu TVA)</TableCell>

                                            </TableRow>
                                        </TableHead>

                                        {item.products.map((e, i) => (
                                            <TableBody key={i} >
                                                <TableRow
                                                    onClick={setProductModalOpenHandler(e, i)}
                                                    onContextMenu={rowContextHandler(e, i)}
                                                    style={{ background: getOutlineColor(e.id) }}
                                                >
                                                    <TableCell align="center">{i + 1}</TableCell>
                                                    <TableCell>{e.name}</TableCell>
                                                    <TableCell align="center">{e.cpv && e.cpv.code ? e.cpv.code : "nedefinit"}</TableCell>
                                                    <TableCell align="center">{e.procedura && e.procedura.name}</TableCell>
                                                    <TableCell align="center">{e.unit}</TableCell>
                                                    <TableCell align="right" >{parseFloat(e.price).toFixed(2)} &nbsp; &nbsp;</TableCell>
                                                    <TableCell align="center">{parseFloat(e.amount).toFixed(2)}</TableCell>
                                                    <TableCell align="center">{parseFloat(e.vat).toFixed(2)}</TableCell>

                                                    <TableCell align="right">{(e.price * e.amount).toFixed(2)} &nbsp; &nbsp;</TableCell>
                                                    <TableCell align="right">{(e.price * e.amount * (e.vat ? 1 + e.vat / 100 : 1)).toFixed(2)}
                                            &nbsp; &nbsp;</TableCell>
                                                </TableRow>
                                                {e.props && e.props !== "" && (
                                                    <TableRow >
                                                        <TableCell colSpan={10}>
                                                            <strong><i>Mențiuni: </i></strong>{e.props}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        ))}

                                        {item.products.length > 0 ? (
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={8} align="center"><strong>TOTAL</strong></TableCell>
                                                    <TableCell align="right"><strong>{calcTotal(item.products, false).toFixed(2)}</strong>  &nbsp;&nbsp;</TableCell>
                                                    <TableCell align="right"><strong>{calcTotal(item.products, true).toFixed(2)}</strong> &nbsp;&nbsp;</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ background: "none", border: "none" }} colSpan={10}>
                                                        <Alert severity = "info" style = {{padding:"0 0 0 10px"}}>
                                                        <Typography variant="caption" color="secondary">
                                                            <span style={{ color: "navy" }}>
                                                                click pe un rând - <strong>MODIFICARE</strong>,</span> click dreapta pe un rând - <strong>ȘTERGERE</strong>
                                                        </Typography>
                                                        </Alert>
                                                    </TableCell>
                                                </TableRow>

                                            </TableFooter>
                                        ) : (
                                                <TableFooter>
                                                    <TableRow >
                                                        <TableCell style={{ background: "none", border: "none" }} colSpan={10}>
                                                            <Typography variant="caption" color="primary">
                                                                folosiți butonul <FaPlusCircle /> din dreapta capului de table pentru a adăuga produse,servicii sau lucrări în referat!
                                                </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            )}

                                    </Table>

                                </div>

                            </RichCard>
                        </Grid>

                    <Grid item xs = {12}>
                    
                        <Grid container justify = "flex-start" align-items = "flex-end" spacing = {1}>
                            <Grid item xs={1}>
                                <Typography variant = "caption">Status referat:</Typography>
                            </Grid>
                            {statuses.map( (elem, index) => (
                                <Grid item xs = {1} key = {index}>
                                    
                                    <Button 
                                        variant =  {item.status === elem.name ? "contained" : "outlined"}
                                        color = {item.status === elem.name ? "secondary" : "primary"}
                                        disabled = {getStatusDisablement(state.role, statuses, elem, index, item.status)}
                                        onClick = {() => setItem({...item, status:elem.name})}
                                        size = "small" fullWidth>
                                        {elem.name}
                                    </Button>
                                </Grid> 
                            ))}
                           
                        </Grid>

                    </Grid>
                    </Grid>
                    {/* { item && (<pre>{JSON.stringify(item, null, 4)}</pre>)} */}
                </Container>
            )}

            <ProductModal data={productModal} onClose={productModalCloseHandler} />
            <PrintModal data={printModal} onClose={() => setPrintModal()} />
            <Confirm {...{
                title: "ȘTERGERE",
                open: Boolean(confirmModal),
                onClose: removeHandler
            }} />
        </Fragment>
    )
}

export default Referat