import React, { Fragment } from 'react'
import { Tab, Tabs, Box, Typography, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab';

export default function ImportDate() {
    return (
        <Fragment>
            <Alert severity="info">
                Apăsați butonul de mai jos pentru a alege din calculatorul Dvs un fișier de backup în format json, în care ați salvat anerior
                datele Dvs. din aplicație
    </Alert>
            <div align="center" style={{ padding: "5rem" }}>
                <Button variant="contained" color="primary" size="large">
                    restaurare
   </Button>
            </div>
        </Fragment>
    )
}
