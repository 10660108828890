import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Grid, TextField, IconButton, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import moment from "moment"
import { FaTrash } from "react-icons/fa"
import Confirm from '../../components/Confirm'
import { useMutation } from '@apollo/client'
import {PROGRAM_REMOVE_MUTATION} from '../../apollo/mutation'

const ProgramModal = props => {

    const [item, setItem] = useState()
    const [confirm, setConfirm] = useState(false)
    const [removeProgram, __] = useMutation(PROGRAM_REMOVE_MUTATION)
    const inputHandler = name => ev => setItem({...item, [name]: ev.target.value})
    const dateHandler = name => ev => setItem({...item, [name]: moment(ev.target.value).toDate()})

    useEffect(() => {
        setItem(props.data)
    }, [props.data])

    const closeHandler = name => ev => {
        console.log("modal on close: ", name)
        props.onClose(name)
    }

    const removeHandler = () => {
        setConfirm(true)
    }

    const confirmHandler = ev => {
        if  ( ev ) { 
            removeProgram({variables: {id:item.id}}).then( 
                r =>  props.onClose(true,true),
                err => { console.log("err:", err); }
            )
        }
        setConfirm(false)
    }

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler()}>
            <DialogTitle>
                <Grid container justify = "space-between" alignItems = "center"> 
                    <Grid item>
                        {item && item.id ? "MODIFICARE" : "CREERE"} PROGRAM
                    </Grid>
                    <Grid item>
                        <Button color = "secondary" size ="small" variant = "outlined" onClick = {removeHandler}>
                            <FaTrash/> <Typography variant = "caption">&nbsp;&nbsp;ȘTERGERE PROGRAM</Typography>
                        </Button>
                    </Grid>
                </Grid>
            
                
            </DialogTitle>
            <DialogContent>
                {item &&(
                    <Grid container justify = "flex-start" spacing = {1}>
                        <Grid item xs = {12}>
                            <TextField
                                variant = "outlined" size = "small" fullWidth label = "denumire"
                                value = {item.name}
                                onChange = {inputHandler('name')}
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {3}>
                            <TextField
                                variant = "outlined" size = "small" fullWidth label = "nr.doc."
                                value = {item.nrdoc}
                                onChange = {inputHandler('nrdoc')}
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {5}>
                            <TextField
                                variant = "outlined" size = "small" fullWidth label = "data doc." type ="date"
                                value = {moment(item.datadoc).format('YYYY-MM-DD')}
                                onChange = {dateHandler('datadoc')}
                            />
                        </Grid>
                        {/* <pre>{JSON.stringify(item, null, 4)} </pre> */}
                    </Grid>
                  
                )}
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "secondary" onClick = {closeHandler()}>RENUNȚĂ</Button>
                <Button variant = "contained" color = "primary" onClick = {closeHandler(item)}>CONFIRMĂ</Button>
            </DialogActions>
            <Confirm open = {confirm} onClose = {confirmHandler} title = "Ștergere program!"/>
        </Dialog>
    )
}

export default ProgramModal