import { useQuery, useMutation } from '@apollo/client'
import { REFERAT_REMOVE_MUTATION } from '../../apollo/mutation' 
import {
    Button,
    Grid,
    IconButton, makeStyles,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery, useTheme
} from "@material-ui/core"
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FaArrowAltCircleRight, FaCaretDown, FaCaretRight, FaPlusCircle } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { REFERATE_QUERY } from '../../apollo/query'
import Container from '../../components/Container'
import ReactTable from '../../components/ReactTable7'
import { SelectColumnFilter } from '../../components/ReactTable7/filters'
import { Context } from '../../components/Store'
import { totalReferat } from '../../lib/compute'




const useStyles = makeStyles(theme => ({
    card: { padding: theme.spacing(1), background: theme.palette.primary.dark, color: theme.palette.primary.contrastText },
    listItem: {},
    gridListTileContent: {
        // margin: theme.spacing(1),
        padding: theme.spacing(2),
        background: theme.palette.grey[200], color: theme.palette.contrastText,
        "&:hover": { background: theme.palette.primary.dark, color: theme.palette.primary.contrastText }
    }
}))

const ReferateleMele = props => {
    const classes = useStyles()
    const [state, dispatch] = useContext(Context)
    const [company, setCompany] = useState([])
    const [editModal, setEditModal] = useState()
    const History = useHistory()
    const width = useMediaQuery(useTheme().breakpoints.up('sm'))
    const [referate, setReferate] = useState([])
    const [expanded, setExpanded] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [currentItem, setCurrentItem] = useState()
    const [removeReferatMutation, _ ] = useMutation(REFERAT_REMOVE_MUTATION)

    const { data, error, loading, refetch } = useQuery(REFERATE_QUERY, { variables: {
        "data": {
            // "like": {
            //     "key": "id",
            //     "value": ""
            // },
            "chunk": {
                "offset": 0,
                "limit": 100000
            },
            "ordering": {
                "row": "id",
                "order": "asc"
            }
        }
    } })

    useEffect(() => {

        if (data) {
            console.log(data)
            // let r =  data && data.referate  || {}
            if ( data && data.referate ){
                let rf = [...data.referate].map( e => ({...e, dt: moment(e.datadoc).format('DD.MM.YYYY'), total: totalReferat(e)}))
                setReferate(rf)
            }


        }
    }, [data])

    const referatRemoveHandler = () => {
        removeReferatMutation({variables: {id: currentItem.id}})
        .then ( 
            r => { if ( r ) { setAnchorEl(); setCurrentItem(); refetch() }},
            err => console.log(err)
        )
    }


    const columns = useMemo(() => [
        {
            id: 'expander',
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                <span {...getToggleAllRowsExpandedProps()}>
                        {isAllRowsExpanded
                            ? <FaCaretDown style={{ fontSize: "2rem", color: "navy", marginTop: "20px" }} />
                            : <FaCaretRight style={{ fontSize: "2rem", color: "grey", marginTop: "20px" }} />}
                </span>
            ),
            

            Cell: ({ row }) => <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                        })} >
                        {row.isExpanded
                            ? <FaCaretDown style={{ fontSize: "1.5rem", color: "navy" }} />
                            : <FaCaretRight style={{ fontSize: "1.5rem", color: "grey" }} />}
                    </span>
              
        },
        {
            Header: <div align = "center"><strong>Unitate</strong></div>,
            accessor: "company.name",
            Filter: SelectColumnFilter,
            Cell: ({row}) => (<div align ="center">
            <small>{row.original.company && row.original.company.name}</small>
        </div>)
        },
        {
            Header: <div align = "center"><strong>Departament</strong></div>,
            accessor: "department.name",
            Filter: SelectColumnFilter,
            Cell: ({row}) => (<div align ="center">
                <small>{row.original.department && row.original.department.name}</small>
            </div>)
        },
        {
            Header: <div align = "center"><strong>Utilizator</strong></div>,
            accessor: "user.name",
            Filter: SelectColumnFilter,
            Cell: ({row}) => (<div align ="center">
            <small>{row.original.user && row.original.user.name}</small>
        </div>)
        },
        {
            Header: <div align = "center"><strong>Nr.doc.</strong></div>,
            accessor: "nrdoc", 
            Cell: ({row}) =>
                <div align="center"><small>{row.original.nrdoc}</small></div>
        },
        {
            Header: <div align = "center"><strong>Data doc.</strong></div>,
            accessor: "dt", 
            Cell: ({row}) =>
                <div align="center"><small>{row.original.dt }</small></div>
        },
        {
            Header: <div align = "center"><strong>Descriere pe scurt</strong></div>,
            accessor: "necesitate", 
            Cell: ({row}) =>
                <small>{row.original.necesitate}</small>
        },
        {
            Header: <div align = "center"><strong>Total(lei)</strong></div>,
            accessor: "total", 
            Cell: ({row}) =>
                <div align = "right"><small>{row.original.total}</small></div>
        },
        {
            Header: <div align = "center"><strong>Stare</strong></div>,
            accessor: "status", Filter: SelectColumnFilter, 
            Cell: ({row}) =>
                <div align="center"><small>{row.original.status}</small></div>
        },
        {
            id: "actiuni", Cell: ({row}) => (
                <IconButton
                    size="small" color="primary"
                    onClick={() => props.history.push(`/referat/${row.original.id}`)}>
                    <FaArrowAltCircleRight />
                </IconButton>
            )
        }

    ], [])

    

    return (
        <div style={{ width: "100%", overflow: "hidden" }}>
            <Container title={
                <Typography variant = "h5">
                    <strong>Registrul referatelor de necesitate</strong>
                    {referate && <small> &nbsp;&nbsp;({referate.length} referate)</small>}
                </Typography>
            }
                
            >

                <ReactTable
                    data={referate}
                    defaultPageSize={5}
                    // clickedRow = {row => props.history.push(`/referat/${row.id}`)}
                    columns={columns}
                    clickedRow = { (row,ev) => {
                        setAnchorEl(ev.currentTarget)
                        setCurrentItem(row)
                    }}
                    
                    renderRowSubComponent={({row}) => (
                        <ReactTable 
                            style = {{background: "rgba(3,169,244,0.16)"}}
                            data = {row.original.products}

                            columns = {[
                                {
                                    Header: <div align ="center"><strong>#</strong></div>, 
                                    id: "itemid", 
                                    Cell: ({row}) => <div align = "center">{row.index+1}</div>
                                },
                                {
                                    Header: <div align ="left" style = {{paddingLeft: "10px"}}><strong>Denumire</strong></div>, 
                                    id: "DENUMIRE", 
                                    Cell: ({row}) => <div align = "left">{row.original.name}</div>
                                },
                                {
                                    Header:<div align ="left" style = {{paddingLeft: "10px"}}><strong>Preț</strong></div>, 
                                    id: "PREȚ", 
                                    Cell: ({row}) => <div align = "left">{row.original.price}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>TVA(%)</strong></div>, 
                                    id: "TVA", 
                                    Cell: ({row}) => <div align = "center">{row.original.vat}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>Cantit.</strong></div>, 
                                    id: "cantit", 
                                    Cell: ({row}) => <div align = "center">{row.original.amount}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>U.M.</strong></div>, 
                                    id: "um", 
                                    Cell: ({row}) => <div align = "center">{row.original.unit}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>CPV</strong></div>, 
                                    id: "cpv", 
                                    Cell: ({row}) => <div align = "center">
                                        {
                                            row.original.cpv && row.original.cpv.code 
                                                ? row.original.cpv.code 
                                                :"nedefint"
                                        }</div>
                                },

                            ]}

                        />
                    )}
                />


                {/* <pre>{user && JSON.stringify(user, null, 4)}</pre> */}
                <Menu open = {Boolean(anchorEl)} anchorEl = {anchorEl} onClose = {() => setAnchorEl()}>
                    <div style = {{padding: "10px", textAlign:"center"}}>
                            <Typography variant ="caption">STERGERE REFERAT NR. 
                            <strong>
                                 {currentItem && currentItem.nrdoc} / {currentItem && moment(currentItem.datadoc).format('DD.MM.YYYY')}
                            </strong>
                            </Typography>
                            <br/>  <br/>  
                            <Grid container spacing = {2} justify = "space-around">
                            <Button 
                                onClick = {referatRemoveHandler}
                                color ="primary" variant = "contained"
                            >CONFIRMĂ</Button>
                            <Button 
                                onClick = {() =>{setAnchorEl();setCurrentItem()}}
                                color ="secondary" variant = "contained"
                            >RENUNȚĂ</Button>

                            </Grid>
                    </div>
                </Menu>
                {/* {console.log("cur:", currentItem)} */}
            </Container>
        </div>

    )
}

export default ReferateleMele