
import React, {useEffect, useState} from 'react'
import {Snackbar} from "@material-ui/core"
import {Alert} from '@material-ui/lab'

export default function Notification(props) {
    
    const [success, setSuccess] = useState(false)

    useEffect(() => {
    //    console.log("eff:", props.show)
        setSuccess(props.show)
    }, [props])

    const closeHandler = () => props.onClose()

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={success}
            autoHideDuration={1000}
            onClose={closeHandler}>
            <Alert severity="error">
                OPERAȚIUNE FINALIZATĂ CU SUCCES!
            </Alert>
        </Snackbar>
    )
}




