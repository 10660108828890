import { useQuery } from "@apollo/client"
import { Button, Divider, Grid, makeStyles, MenuItem, Typography } from "@material-ui/core"
import moment from 'moment'
import { Fragment, useRef, useState } from 'react'
import { FaPrint } from "react-icons/fa"
import { useReactToPrint } from 'react-to-print'
import { PROGRAME } from '../../apollo/query'
import Container from "../../components/Container"
import RichCard from "../../components/RichCard"
import ProgramAnualTemplate from './ProgramAnualTemplate'
import styled from 'styled-components'
import AnexaAchizitiiDirecteTemplate from "./AnexaAchizitiiDirecteTemplate"
// import './style.css'

const useStyles = makeStyles(theme => ({
    printRoot: { },
    printable: {},
        '@media screen': {
            margin: theme.spacing(3),
            padding: theme.spacing(1)
        }

    }))
const Styles = styled.div`

.landscape{
    @media print {
        @page { 
            size:  landscape;
            margin: 10mm 10mm 10mm 30mm; 
        }
        body { 
            
            /* writing-mode: tb-rl; */
        }
    }
}

`

const ProgramAnual = () => {
    const classes = useStyles()
    const { data } = useQuery(PROGRAME)
    const [program, setProgram] = useState()
    const printRef = useRef()
    const [tabIndex, setTabIndex] = useState(0)

    const programHandler = prog => () => setProgram({...prog})
    


    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Styles>
        <Container
            style = {{height: "75vh"}}
            title="Rapoarte / Programul anual al achiziților publice">
            {data && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} lg={3}>
                        <RichCard title="Programe" >
                            {data && data.programe && data.programe.map((e, i) => (
                                <Fragment key={i}>
                                    <MenuItem onClick={programHandler(e)}>
                                        <Typography variant="caption">
                                            <strong>{e.name} </strong><br />
                                            (nr. {e.nrdoc} / {moment(e.datadoc).format('DD.MM.YYYY')})

                                            </Typography>
                                    </MenuItem>
                                    <Divider />
                                </Fragment>
                            ))}

                        </RichCard>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={9}>
                        <div style = {{display: "flex"}}>
                                <Button 
                                    variant =  {tabIndex === 0 ? "outlined": "text"}
                                    size = "small"
                                    color = {tabIndex === 0 ? "primary": "inherit"}
                                    onClick = {() => setTabIndex(0)}>
                                       program anual 
                                    </Button>
                                    <Button 
                                        variant =  {tabIndex === 1 ? "outlined": "text"}
                                        color = {tabIndex === 1 ? "primary": "inherit"}
                                        onClick = {() => setTabIndex(1)}>
                                            anexa achizitii directe
                                    </Button>
                        </div>
                                <div align ="right">
                                <Button onClick = {printHandler}>
                                    <FaPrint/> &nbsp;&nbsp; TIPĂRIRE
                                </Button>
                                </div>
                             <div style = {{height: "55vh", overflow:"auto", border: "1px solid grey", padding: "10px"}}>
                            {program 
                                ? <div ref = {printRef}>
                                    {/* <div > */}
                                   {tabIndex === 0 
                                    ?   <ProgramAnualTemplate data = {program} />
                                    :   <AnexaAchizitiiDirecteTemplate  data = {program} />
                                    
                                    }
                                    
                                    </div>
                                : <Typography variant="body1" color="secondary">
                                    Alegeți un program
                                </Typography>
                            }
                      </div>
                    </Grid>

                </Grid>
            )}


        </Container>
        </Styles>
    )
   
}

export default ProgramAnual