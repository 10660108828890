import React, { useState, useEffect, useContext } from 'react'
import {Context} from '../../components/Store'
import { makeStyles, Grid, Button, Typography, Toolbar, IconButton, Card } from '@material-ui/core'
import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import {USER_REMOVE_MUTATION} from '../../apollo/mutation'
import RichInput from '../../components/RichInput'
import Confirm from '../../components/Confirm'
import UserModal from './UserModal'

const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1 },
    toolbar: {
        background: theme.palette.grey[200], fontWeight: 800, marginBottom: theme.spacing(2),
        display: "flex", justifyContent: "space-between",
        paddingRight: theme.spacing(0.5)
    },
    container: {padding: theme.spacing(1), border: "1px solid grey", maxHeight: "60vh", overflowY: "scroll"},
    card: {padding: theme.spacing(1)}
}))

const FIELDS = [
    {name: "name"}
]

export default function Users(props) {
    const classes = useStyles()
    const [state, setState] = useContext(Context)
    const [users, setUsers] = useState()
    const [departments, setDepartments] = useState([])
    const [removeModal, setRemoveModal] = useState()
    const [userModal, setUserModal] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [userRemove, __] = useMutation(USER_REMOVE_MUTATION)

    useEffect(() => {
        if (props.departments) {
            setUsers(props.departments.reduce( (acc, item) => { return [...acc, ...item.users]}, []))
            setDepartments(props.departments.map(el => ({ id: el.id, name: el.name })))
        }
    }, [props])


    const addItemHandler = () => {
        setUserModal({ 
            name: "",
            username: "",
            password: "",
            email: "",
            role: "user",
            company_id: state.current_company_id,
            department_id: null
        })
    }


    const removeModalOpenHandler  = (e,i) => () => {
        setRemoveModal(e); setCurrentIndex(i)
    }
    const removeHandler = ev => {
        if (ev) { 
            userRemove({variables: {id: users[currentIndex].id}}).then(
                r => {
                    if (r) {
                        let newUsers = [...users]
                        newUsers.splice(currentIndex, 1)
                        props.onSubmit(true)
                    } 
                    
                },
                err => console.log("user remove err:", err)
            )
        }
        setRemoveModal(); setCurrentIndex()
    }


    const editModalOpenHandler = (e,i) => () => {
        setUserModal(e); setCurrentIndex(i)
    }

    const userModalCloseHandler = ev => {
        setUserModal(); setCurrentIndex()
        props.onSubmit(ev)
    }

    return (
        <div className={classes.root}>
            <Toolbar variant="dense" className={classes.toolbar}>
                ADMINISTRARE UTLIZATORI
                <div align="right">
                    <IconButton size="medium" color="primary" onClick={addItemHandler}>
                        <FaPlusCircle />
                    </IconButton>
                </div>
            </Toolbar>
            <div className={classes.container}>
                {users && (
                    <Grid container spacing={1} alignItems="center">
                        { users.map( (e,i) => (
                            <Grid item xs = {12} key = {i}>
                                {/* <Card className = {classes.card}> */}
                                <RichInput multiline
                                    disabled
                                    value = {`${e.name} \nuser:           ${e.username}\ndept:\           ${e.department.name}\nrol:              ${e.role}`}
                                    start = {<div>{i+1}. &nbsp;</div>}
                                    end = {
                                        <div style = {{display: "flex"}}>
                                            <IconButton 
                                                size = "small" color = "secondary" 
                                                disabled = {e.referate.length>0}
                                                onClick = {removeModalOpenHandler(e,i)}>
                                                <FaTimesCircle/>
                                            </IconButton>
                                            &nbsp;&nbsp;&nbsp;
                                            <IconButton size = "small" color = "primary" onClick = {editModalOpenHandler(e,i)}>
                                                <FaEdit/>
                                            </IconButton>
                                        </div>
                                    }
                                />
                                {/* </Card> */}
                            </Grid>
                        ))}
                        {/* <pre>{props && JSON.stringify(props, null, 2)}</pre> */}
                        <Confirm open = {removeModal} onClose = {removeHandler} />
                        <UserModal data = {userModal} departments = {departments} onClose = {userModalCloseHandler} />
                    </Grid>
                )}
            </div>
        </div>

    )
}
