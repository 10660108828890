import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Toolbar, Typography,
Table, TableHead, TableBody,TableFooter, TableRow, TableCell } from '@material-ui/core'
import { Fragment, useRef } from 'react'
import { FaPrint, FaTimesCircle } from 'react-icons/fa'
import { useReactToPrint } from 'react-to-print';
import { PrintContainer } from './PrintContainer';

import moment from 'moment'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: "98vh",
        margin: 0,
        minWidth: "60vw",
        // padding: 0
    },
    toolbar: {
        background: theme.palette.grey[200]
    },
    printable: {
        '@media print': {
            margin: "10mm 20mm 10mm 20mm",
            padding: theme.spacing(1),
            zoom: 0.85
        },
        '@media screen': {
            margin: theme.spacing(3),
            padding: theme.spacing(1)
        }

    },
    paragraph: { textAlign: "justify",   textJustify: "inter-word" },
    table: {
        tableLayout: "auto",
        width: "100%",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid grey",
            background: theme.palette.grey[200],
            wordWrap: "normal",
            fontSize: "0.7rem",
            padding: theme.spacing(0.7),
            lineHeight: 1.4
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.7)
        },
        '&& tbody>tr>td' : {
            wordWrap: "break-word"
        },
    }
}))
const PrintModal = props => {
    const printRef = useRef()
    const classes = useStyles()

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });
       
    
    const closeHandler = () => {
        props.onClose()
    }

    const companyProps = d => d.company.props  ? JSON.parse(d.company.props) : {}

    const calcTotal = (arr, hasTva) => {
        return arr 
        ?  arr.reduce((acc,item)=>{
            acc = acc + parseFloat(item.amount) * parseFloat(item.price) * (hasTva ? 1+item.vat/100 : 1)
            return Math.round(acc*100)/100
        }, 0)
        : 0
    }

    return (
        <Fragment>
            {props.data && (
                <Dialog open={Boolean(props.data)} onClose={closeHandler} classes = {{paper: classes.dialogPaper}}>
                <DialogTitle style = {{padding: 0}}>
                    <Toolbar className = {classes.toolbar}>
                    <Grid container justify="space-between" alignItems = "center">
                        <Typography variant="h5">TIPĂRIRE REFERAT DE NECESITATE</Typography>
                        <Grid item>
                            <IconButton onClick={printHandler}><FaPrint /></IconButton>
                            <IconButton onClick={closeHandler}><FaTimesCircle /></IconButton>
                        </Grid>
                    </Grid>
                    </Toolbar>

                </DialogTitle>
                <DialogContent style = {{width: "100%"}}>
                    {/* <PrintContainer > */}
                    <div ref={printRef} className = {classes.printable}>
                    {props.data && (
                        <Grid container justify = "space-between" alignItems = "flex-start" spacing = {2}>
                            <Grid item xs = {8} align = "left">
                                <Typography variant = "h6" color = "inherit"> {props.data.company.name}</Typography>
                                
                                <Typography variant = "caption" color = "inherit">
                                      {companyProps(props.data).adresa}
                                </Typography>
                                <Typography variant = "caption" color = "inherit">
                                      CF: {companyProps(props.data).cui}
                                </Typography>
                            </Grid>
                                <Grid item xs ={3}>
                                    Aprobat
                                    <br/>
                                    Conducator unitate, <br/>
                                    . . . . . . . . . . . . . . . .
                                </Grid>
                            <Grid item xs = {12} align = "right">
                                <i>
                                Nr {props.data.nrdoc || '__________'} din {moment(props.data.datadoc).format('DD.MM.YYYY')}
                                </i>
                            </Grid>
                            <Grid item xs = {12} align = "center">
                                <Typography variant = "h5" style = {{fontWeight: 800}}>
                                    REFERAT DE NECESITATE
                                </Typography>
                            </Grid>


                            <Grid item xs = {12} className = {classes.paragraph}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Subsemnatul/subsemnata, {props.data.user.name}, angajat la {props.data.company.name}, având funcția de {props.data.user.role}, vă rog să aprobați cheltuirea sumei de {' '}
                                {calcTotal(props.data.products, true)} lei, necesară pentru:
                                <Grid item xs = {12} className = {classes.paragraph}>
                                    <div align = "center"><strong><i>{props.data.necesitate}</i></strong></div>
                                    
                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    În acest sens, vă rog să aprobați achiziționarea următoarelor produse/lucrări/servicii:
                               </Grid>
                            </Grid>
                            <Grid item xs ={12} className ={classes.paragraph} style = {{maxWidth: "100%"}}>
                                <Table className = {classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small"> Nr.crt.</TableCell>
                                            <TableCell size="medium" style = {{textAlign: "center"}}> Denumire produs,<br/>serviciu, lucrare</TableCell>
                                            <TableCell size = "medium" align = "center"> C.P.V. </TableCell>
                                            <TableCell size = "medium" align = "center">Procedură</TableCell>
                                            <TableCell size = "small" align = "center"> U.M.</TableCell>
                                            <TableCell size = "small" align = "center"> P.U.</TableCell>
                                            <TableCell size = "small" align = "center"> Cantit.</TableCell>
                                            <TableCell size = "small" align = "center"> TVA</TableCell>
                                            <TableCell size = "small" align = "center"> Total<br/>fără TVA</TableCell>
                                            <TableCell size = "small" align = "center"> Total<br/>cu TVA</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    
                                        {props.data.products.map( (e,i) => (
                                        <TableBody key = {i}>
                                            <TableRow  >
                                                <TableCell>{i+1}</TableCell>
                                                <TableCell>{e.name}</TableCell>
                                                <TableCell>{e.cpv && e.cpv.code ? e.cpv.code: "nedefinit"}</TableCell>
                                                <TableCell>{e.procedura.name}</TableCell>
                                                <TableCell>{e.unit}</TableCell>
                                                <TableCell align = "right">{e.price ? e.price.toFixed(2) : 0}</TableCell>
                                                <TableCell align = "right">{e.amount ? e.amount.toFixed(2) : 0 }</TableCell>
                                                <TableCell>{e.vat ? e.vat.toFixed(2) : "0.00"}</TableCell>

                                                <TableCell align = "right">{(e.price * e.amount).toFixed(2) }</TableCell>
                                                <TableCell align = "right">{(e.price * e.amount * (e.vat ? 1+e.vat/100 : 1)).toFixed(2)}</TableCell>
                                            </TableRow>
                                            {e.props && e.props !== "" && (
                                            <TableRow >
                                                <TableCell colSpan = {10}>
                                                    <strong><i>Mențiuni: </i></strong>{e.props}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        </TableBody>
                                        ))}
                                    
                                    
                                    <TableFooter>
                                    <TableRow>
                                            <TableCell colSpan = {8} align="center"><strong>TOTAL</strong></TableCell>
                                            <TableCell align = "right"><strong>{calcTotal(props.data.products, false).toFixed(2)}</strong></TableCell>
                                            <TableCell align = "right"><strong>{calcTotal(props.data.products, true).toFixed(2)}</strong></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Grid>
                            <Grid item xs = {4} align = "center">
                                Data: <strong>{moment(props.data.datadoc).format('DD.MM.YYYY')}</strong>
                            </Grid>
                            <Grid item xs = {4}/>
                            <Grid item xs = {4} align = "center">
                                Cu stimă, <br/><br/>
                                {props.data.user.name || '. . . . . . . . . . . . . . . . . . . . . . .'}
                            </Grid>


                        </Grid>
                       )}
                        {/* {props && props.data && <pre>{JSON.stringify(props.data, null, 4)}</pre>} */}
                    </div>
                    {/* </PrintContainer> */}
                </DialogContent>
            </Dialog>
            )}
            
        </Fragment>

    )
}

export default PrintModal