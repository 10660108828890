import { gql } from '@apollo/client'

export const COMPANY_QUERY = gql`
query company($id:ID){
  company(id:$id) {
    id name 
    departments{id name
      users{ id name username password role
          referate {
            id company_id department_id user_id datadoc nrdoc necesitate status
            products {
              id company_id department_id user_id ref_id name price vat amount unit duedate start end obs cpv_id clasif_id artbug 
              procedura_id sursa_id props 
              procedura {id company_id}
              cpv { id version code short long }
              sursa { id name desc}
    }
    }
      }
    }
  }
}
`

export const USER_QUERY = gql`
query {
  user {
    id name username password role
    referate {id company_id department_id user_id datadoc nrdoc necesitate status
      products {id company_id department_id user_id ref_id name price vat amount unit duedate start end obs cpv_id clasif_id artbug procedura_id sursa_id props 
        procedura {id company_id}
        cpv { id version code short long }
        sursa { id name desc}
      }
    }
  }
}
`

export const REFERATE_QUERY = gql`
query referate($data:ComplexQuery) {
  referate(data:$data){id company_id department_id user_id datadoc nrdoc necesitate status
    company {id name}
    department {id name }
    user {id name}
      products {id company_id department_id user_id ref_id name price vat amount unit duedate start end obs cpv_id clasif_id artbug procedura_id sursa_id props 
        procedura { id company_id name}
        cpv { id version code short long }
        sursa{ id name desc}
      }
  }
}`

export const REFERAT_QUERY = gql`
query referat($id:ID) {
  referat(id:$id){id company_id department_id user_id datadoc nrdoc necesitate status
    company {id name props}
    department {id name }
    user {id name username email role company_id department_id}
    products {id company_id department_id user_id ref_id name price vat amount unit duedate start end obs cpv_id clasif_id artbug procedura_id sursa_id props 
      procedura {id name}
      cpv { id version code short long }
      sursa { id name desc}
    }
  }
}`

export const COMPANY = gql`{
  company {
    id name 
    proceduri {id name desc}
    clasificatie { id name ordering }
    surse{ id name desc}
  }
}`
export const COMPANIES = gql`{
companies{
  id name props
  departments {
    id name 
    users {
      id name username email role company_id department_id
      company {id name props} department { id name desc}
      referate{id} 
    }
  }
  clasificatie { id company_id  name props }
  proceduri {id name desc }
  surse {id name desc}

}}`

export const CPV = gql`
  query cpv($data: ComplexQuery) {
      cpvs(data: $data){
        id code short long
      }
  }
`

export const PROGRAME = gql`
query programe {
  programe {
    id name datadoc nrdoc items
    referate {
      id company_id department_id user_id nrdoc datadoc  necesitate status
      products { 
        id company_id department_id user_id ref_id name price vat amount unit duedate start end obs cpv_id clasif_id artbug   
        procedura_id sursa_id props 
        procedura {id name}
        sursa {id name desc}
        cpv {id code short} 
      }
      user { id name username}
      department {id name}
      company{id name}
    }

  }
}`

export const PROCEDURI = gql`
  query proceduri {
    proceduri {
      id company_id name desc
      company { id name props}
    }
  }
`

export const CLASIFICATIE = gql`
  query clasif {
    clasif {
      id company_id name desc
      company { id name props}
    }
  }
`

