import {useState, useEffect} from 'react'
import {useMutation} from '@apollo/client'
import { USER_MUTATION } from '../../apollo/mutation'
import { Grid, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, IconButton, MenuItem } from "@material-ui/core"
import { FaTimesCircle } from 'react-icons/fa'
import RichInput from '../../components/RichInput'

const useStyles = makeStyles(theme => ({
    toolbar: {  display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: 0 },
    dialogPaper: {  overflow: "auto"    },
    dialogTitle: { background: theme.palette.grey[200], padding: 0, margin: 0}
    
}))

const FIELDS = [
    {name:  "name",     xs: 12, sm: 9},
    {name:  "role",     xs: 12, sm: 3},
    {name:  "username", xs: 12, sm: 4},
    {name:  "password", xs: 12, sm: 4},
    {name:  "email",    xs: 12, sm: 4},
    
]

export default function UserModal({data, departments, onClose}){
    const classes = useStyles()
    const [item, setItem] = useState()
    const [deps, setDeps] = useState([])
    const [submit, _] = useMutation(USER_MUTATION)

    useEffect(() => {
        setItem(data)
        if (departments) setDeps(departments)
    }, [data])
  
    const closeHandler = ev => () => {
        console.log("Ev:", ev)
        if ( !ev ) { onClose() } else {
            submit({variables: {data: {
                id: item.id, 
                name: item.name,
                username: item.username, 
                password: item.password,
                role: item.role,
                email: item.email,
                company_id: item.company_id,
                department_id: item.department_id
            }}}).then( 
                r => onClose(true),
                err => console.log("err:", err)
            )
        }
    }

    const inputHandler = name => ev => setItem({...item, [name]: ev.target.value})

    return (
        <Dialog 
            open = {Boolean(item)} 
           
            classes = {{ paper:classes.dialogPaper}}  
            onClose = {closeHandler()}
        >
            <DialogTitle className = {classes.dialogTitle}>
                <Toolbar variant = "dense" className = {classes.toolbar}>
                    {item && item.name ? item.name : "Utilizator nou"}
                    <IconButton onClick ={closeHandler()}><FaTimesCircle/></IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent style = {{overflow: "auto"}}>
                <Grid container justify = "flex-start" alignItems = "center" spacing = {1}>
                    {item && FIELDS.map((e,i) => (
                        <Grid item key = {i}  xs = {e.xs} sm = {e.sm}>
                        <RichInput value = {item[e.name]|| ""} label = {e.name} onChange = {inputHandler(e.name)}/>
                        </Grid>
                        ))
                    }
                    {item && departments && (
                        <Grid item xs = {12}>
                            <RichInput select value = {item.department_id} onChange = {inputHandler('department_id')}>
                                {departments.map( (e,i) => (
                                    <MenuItem key ={i} value = {e.id}>{e.name}</MenuItem>
                                ))}
                            </RichInput>
                        </Grid>
                    )}

                 {/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
                 </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "primary" onClick = {closeHandler(item)}>CONFIRMĂ</Button>
                <Button variant = "contained" color = "secondary" onClick ={closeHandler()}>RENUNȚĂ</Button>
            </DialogActions>
        </Dialog>
    )
}

