import {makeStyles, TextField, InputAdornment} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    inputProps: {
        fontSize: "0.8rem",fontWeight: 400, color: theme.palette.primary.dark
    },
    inputLabel: {fontSize: "0.7rem", fontWeight: 400, color: theme.palette.primary.light}
}))

export default function RichInput(props){
    const classes = useStyles()
    return (
        <TextField variant = "outlined" size = "small" fullWidth
            {...props}
            InputProps = {{
                className: classes.inputProps,
                startAdornment: props.start ? <InputAdornment position="start">{props.start}</InputAdornment>: null,
                endAdornment: props.end ? <InputAdornment position="end">{props.end}</InputAdornment>: null,
            }} 
            InputLabelProps = {{className: classes.inputLabel}}
        />
    )
}