import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    input: { fontSize: "0.9em"}
}))

export const Filter = ({ column }) => {
    const classes = useStyles()
    return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
    const classes = useStyles()
  return (
    <TextField variant = "outlined"
    size = "small"  
    InputProps = {{className: classes.input}} 
    fullWidth
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`filtează (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const classes = useStyles()
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <TextField
      id={`custom-select-${id}`}
      select
      fullWidth
      InputProps = {{className: classes.input}} 
      variant = "outlined"
      size = "small"
      value={filterValue}
      
      SelectProps={{ native: true, }}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>-</option>
      {options.map((option, i) => (
        <option key={`option-${i}`} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  );
};