import React, { Fragment, useEffect } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
// import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { TextField, Button, makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Filter, DefaultColumnFilter } from './filters';
import { FaBackward, FaCaretLeft, FaCaretRight, FaFastBackward, FaFastForward, FaForward, FaPlay } from 'react-icons/fa';
import { FastRewind } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  footer: {
    position: "fixed",
    marginBottom: '20px',
    bottom: "0",
    width: "100%",
    display: "flex",
    // border: "2px solid green",
    height: "60px",
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.dark,
    textAlign: "center",
    zIndex: 100
  },
  fixedHeader: {
    overflowY: "auto",
    width: "100%",
    "&& thead th": { position: "sticky", top: 0 },
    "&& tbody": { height: "50vh", border: "1px solid red" },
    "&& th": { boxShadow: "1px 1px 0 #000", borderTop: 0 },
    "&& thead>tr>th": { border: "1px dotted lightgrey", padding: theme.spacing(0.5) },
    "&& tbody>tr:nth-child(odd)": { backgroundColor: "#d3d3d347" },
    "&& tbody>tr:nth-child(even)": { backgroundColor: 'rgba(0,0,0,0.12)' },
    "&& tbody>tr:hover": { backgroundColor: "#3f51b578" },
    "&& tbody>tr>td": { padding: theme.spacing(0.8) }
  }
}))

const ReactTable7 = ({ columns, defaultPageSize, height,  data, renderRowSubComponent, clickedRow, style,
disablePagination  }) => {

  const classes = useStyles()
  const width = useMediaQuery(useTheme().breakpoints.up('sm'))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const onRowClick = row => ev => { 
    ev.preventDefault();
    clickedRow && clickedRow(row, ev) }

  useEffect(()=>{
    if ( defaultPageSize ) { setPageSize(defaultPageSize)}
  }, [])
  
  return (
    <div style = { style }>
      <div style={{ height: width && height ? height: "100%",  border: "1px solid navy", overflow: "auto" }}>
        <table 
          // bordered="true" hover="true"
          {...getTableProps()}
          // classes={{ root: classes.fixedHeader }}
          className={classes.fixedHeader}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td {...column.getHeaderProps()} style={column.style}>
                    <div {...column.getSortByToggleProps()}>
                      <small>{column.render('Header')}</small>
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </td>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={`rw-${i}`} >
                  <tr onContextMenu={onRowClick(row.original)}>
                    {row.cells.map((cell) => {

                      return (
                        <td {...cell.getCellProps()} style={cell.column.style}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length} style = {{background: "white"}}>
                        <div>{renderRowSubComponent({row})}</div>
                      </td>
                    </tr>
                  ):null}
                </Fragment>
              );
            })}
          </tbody>

        </table>
      </div>
          { !disablePagination && (
             <div className={classes.footer} >

             {/* <TableRow style = {{border: "1px solid red"}} > */}
               
             <Grid container justify="space-between" alignItems="center" style={{ width: "80%" }}>
               <Grid item xs={5} sm={5} align="center" style={{ display: "flex" }}>
                 {width && (
                   <Button
                     color='primary'
                     onClick={() => gotoPage(0)}
                     disabled={!canPreviousPage}
                   >
                     <FaFastBackward />
                   </Button>
                 )}
     
                   &nbsp;&nbsp;&nbsp;
                   <Button
                   color='primary'
                   onClick={previousPage}
                   disabled={!canPreviousPage}
                 >
                   <FaCaretLeft style={{ fontSize: "2rem" }} />
     
                 </Button>
                 {width && (
                 <TextField
                   variant="outlined"
                   label=" pagina"
                   type='number'
                   min={1}
                   style={{ width: 70, background: 'white' }}
                   size="small"
                   max={pageOptions.length}
                   defaultValue={pageIndex + 1}
                   onChange={onChangeInInput}
                 />
                 )}
                 <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                   <FaCaretRight style={{ fontSize: "2rem" }} />
                 </Button>
                 {width && (
                   <Button
                     color='primary'
                     onClick={() => gotoPage(pageCount - 1)}
                     disabled={!canNextPage}
                   >
                     <FaFastForward />
                   </Button>
                 )}
               </Grid>
     
               {width && (
                 <Grid item xs={5} style={{ display: "flex", alignItems: "center", justify: "right" }}>
                   afișează &nbsp;
                   <TextField
                     select
                     id="opts-select"
                     // label="pe pag."
                     value={pageSize}
                     onChange={onChangeInSelect}
                     variant="outlined"
                     size="small"
                     style={{ background: "white" }}
                     SelectProps={{
                       native: true,
                     }}
                   >
     
                     {[5, 12, 10, 15, 20, 30, 40, 50].map((pageSize) => (
                       <option key={pageSize} value={pageSize}>
                         {pageSize}
                       </option>
                     ))}
                   </TextField>
                   &nbsp;
                   pe pagină
                 </Grid>
               )}
     
     
             
                 <Grid item xs={5} sm={2} align="right">
                   <>
                     Pagina {' '}
     
                     {pageIndex + 1} din {pageOptions.length}
                   </>
                 </Grid>
               
     
             </Grid>
           </div>
          )}
     



    </div>
  )
};

export default ReactTable7;