import React, { Fragment, useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from "@apollo/client"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Grid, MenuItem, IconButton } from "@material-ui/core"
import {COMPANY, CPV } from '../../apollo/query'
import { Autocomplete } from '@material-ui/lab'
import CpvSelect from './CpvSelect'
import { FaTimesCircle } from 'react-icons/fa'
import moment from 'moment'


const useStyles = makeStyles(theme => {
    return {
    dialogPaper: {
        minWidth: "80vw",
        minHeight: "50vh"
    },
    input: { fontWeight: 800, fontSize: '1em', color: theme.palette.primary.dark},
    textField: { background: "rgba(63,81,181,0.07)", border: "none" },
    
}})
 const ProductModal = props => {
    const classes = useStyles()
    const [item,setItem] = useState()
    const [proceduri, setProceduri] = useState([])
    const [clasificatie, setClasificatie] = useState([])
    const [surse, setSurse] = useState([])
    const company = useQuery(COMPANY)

    const [cpvs, dispatchCvs] = useLazyQuery(CPV)

    useEffect(() => {
        if (props.data) {
            setItem(props.data)
        }
         
         if ( company.data ) {
            // console.log(company.data)
             setProceduri(company.data.company.proceduri)
             setClasificatie(company.data.company.clasificatie)
             setSurse(company.data.company.surse)
         }
    }, [props.data, company.data])

    const closeHandler = () => props.onClose()

    const getOptions = (searchString) => {
        return cpvs({variables: {"data": {
            "like": [
              {"key": "code","value": searchString },
              {"key": "short", "value": searchString }
            ],
            "chunk": {"limit": 100, "offset": 0}
          }}})
    }

    const getTotal = (item, hasVat) => {
        let price, amount, vat
        if ( item ) {
            price = typeof parseInt(item.price) === "number" ? parseFloat(item.price) : 0
            amount = typeof parseInt(item.amount) === "number" ? parseFloat(item.amount): 1
            vat  = typeof parseInt(item.vat) === "number" ? item.vat: 1
            let res =  price * amount * (hasVat ? 1+(vat/100) : 1)
            res = Math.round(res*100)/100
            return res
        } else {
            return 0
        }
    }



    const inputHandler = name => ev => setItem({...item, [name]: ev.target.value})
    const dateHandler = name => ev => setItem({...item, [name]: moment(ev.target.value).toDate()})
    const proceduraHandler = ev => {
        console.log("Ev:", ev, proceduri)
        let pr = proceduri.filter( el => el.id === ev.target.value )[0]
        if (pr) setItem({
            ...item,
            procedura_id: ev.target.value,
            procedura: pr || {}
        })
        
    }
    const submitHandler =  () => {

        props.onClose(item)
    }

    

    return (
        <form id = "formular">
        <Dialog open = {Boolean(props.data)} classes = {{paper: classes.dialogPaper}} onClose = {closeHandler}>
            <DialogTitle>
                <Grid container justify = "space-between" alignItems = "center">
                    {item && item.id ? "Editare" : "Adăugare"} produs
                    <IconButton onClick = {closeHandler}><FaTimesCircle/></IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {item && (
                    <Fragment>
                      
                    <Grid container justify = "flex-start" alignItems = "center" spacing ={2}>
                        <Grid item xs = {12}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small"
                                name = "name" required 
                                label = "produs/serviciu/lucrare"
                                value = {item.name||""}  
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                onChange = {inputHandler('name')}    
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small"
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "unit" required 
                                label = "unit.măsură" 
                                value = {item.unit}  
                                onChange = {inputHandler('unit')}    
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small"
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "price" required type = "number" 
                                label = "preț unitar" 
                                value = {item.price}  
                                onChange = {inputHandler('price')}    
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small"
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "amount" required type = "number" 
                                label = "cantitate" 
                                value = {item.amount}  
                                onChange = {inputHandler('amount')}    
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" select
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "amount" required type = "number" 
                                label = "tva" 
                                value = {item.vat || 0}  
                                onChange = {inputHandler('vat')}    
                            >
                                {[0,5,19].map((e,i)=><MenuItem value = {e} key = {i}>{e}%</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs = {12} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small"
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "amount" required type = "number" 
                                label = "total(fără TVA)" 
                                value = {getTotal(item, false) || 0}  
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small"
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "amount" required type = "number" 
                                label = "total(cu TVA)" 
                                value = {getTotal(item, true) || 0}  
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" select
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "sursa de finanțare" 
                                label = "sursa de finanțare" 
                                value = {item.sursa_id || 0}  
                                onChange = {inputHandler('sursa_id')}
                            >
                                {surse.map((e,i) => (
                                    <MenuItem key = {i} value ={e.id}> {e.name} </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" select
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "procedura" required
                                label = "procedura aplicata" 
                                value = {item.procedura_id}  
                                onChange = {proceduraHandler}
                            >
                                {/* [{id: 1, name: 'ACHIZITIE DIRECTA ONLINE'}, {id:2, name:'ACHIZITIE DIRECTA OFFLINE'}] */}
                                {proceduri.map((e,i) => (
                                    <MenuItem key = {i} value ={e.id.toString()}> {e.name} </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" select
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "clasificatie" required
                                label = "clasificați proprie" 
                                value = {item.clasif_id}  
                                onChange = {inputHandler('clasif_id')}
                            >
                                

                                {clasificatie.map((e,i) => (
                                    <MenuItem key = {'clsif-'+i} value ={e.id.toString()}> {e.name} </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs = {12} sm = {12}>
                            <CpvSelect data = {item} onChange = {ev => setItem(ev)}/>
                        </Grid>


                        <Grid item xs = {6} sm = {3}>
                            Data estimată pentru realizarea achiziției
                        </Grid>
                        <Grid item xs = {6} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" type = "date" fullWidth
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "data estimată" 
                                label = "data estimată" 
                                value = {moment(item.duedate).format('YYYY-MM-DD')}    
                                onChange = {dateHandler('duedate')} 
                            />
                        </Grid>
                        <Grid item xs = {6} sm = {3}>
                            Data estimată pentru finalizarea achiziției
                        </Grid>
                        <Grid item xs = {6} sm = {2}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" type = "date" fullWidth
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "data finalizare" 
                                label = "data finalizare" 
                                value = {moment(item.end).format('YYYY-MM-DD')}    
                                onChange = {dateHandler('end')} 
                            />
                        </Grid>
                        <Grid item xs = {12}>
                            <TextField 
                                fullWidth variant = "outlined" size = "small" multiline rows = {4}
                                InputProps = {{className: classes.input}} 
                                classes = {{root: classes.textField}} 
                                name = "props" required
                                label = "alte mențiuni" 
                                value = {item.props || " "}    
                                onChange = {inputHandler('props')} 
                            />
                        </Grid>
                    </Grid>
                
                {/* {item && <pre>{JSON.stringify(item, null, 3)}</pre>} */}
                    </Fragment>

                )}

            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "secondary" onClick = {closeHandler}>RENUNȚĂ</Button>
                <Button variant = "contained" color = "primary" onClick = {submitHandler}>CONFIRMĂ</Button>
                
            </DialogActions>
        </Dialog>
        </form>
    )

}

export default ProductModal