import { useQuery } from '@apollo/client'
import {
    IconButton, makeStyles,
    Typography,
    useMediaQuery, useTheme
} from "@material-ui/core"
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FaArrowAltCircleRight, FaCaretDown, FaCaretRight, FaPlusCircle } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { USER_QUERY } from '../../apollo/query'
import Container from '../../components/Container'
import ReactTable from '../../components/ReactTable7'
import { SelectColumnFilter } from '../../components/ReactTable7/filters'
import { Context } from '../../components/Store'
import {totalReferat} from '../../lib/compute'




const useStyles = makeStyles(theme => ({
    card: { padding: theme.spacing(1), background: theme.palette.primary.dark, color: theme.palette.primary.contrastText },
    listItem: {},
    gridListTileContent: {
        // margin: theme.spacing(1),
        padding: theme.spacing(2),
        background: theme.palette.grey[200], color: theme.palette.contrastText,
        "&:hover": { background: theme.palette.primary.dark, color: theme.palette.primary.contrastText }
    }
}))

const ReferateleMele = props => {
    const classes = useStyles()
    const [state, dispatch] = useContext(Context)
    const [referate, setReferate] = useState([])
    const [editModal, setEditModal] = useState()
    const History = useHistory()
    const width = useMediaQuery(useTheme().breakpoints.up('sm'))
    const [user, setUser] = useState()
    const [expanded, setExpanded] = useState()

    const { data, error, loading } = useQuery(USER_QUERY, { variables: { id: state.id } })
    console.log("state.id:", state && state.id)
    useEffect(() => {

        if (data) {
            setUser(data.user)
            let referate = (data.user && data.user.referate) || []
            referate = referate.map( elem => ({
                ...elem,
                dt: moment(elem.datadoc).format('DD.MM.YYYY'),
                due: moment(elem.duedate).format('DD.MM.YYYY'),
                // expanded: false,
                canExpand: true,
                total: totalReferat(elem,false)
                


            }))
            setReferate(referate)

        }
    }, [data])

    const memoizedData = useMemo(() => referate)

    const columns = useMemo(() => [
        {
            id: 'expander',
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                <span {...getToggleAllRowsExpandedProps()}>
                        {isAllRowsExpanded
                            ? <FaCaretDown style={{ fontSize: "2rem", color: "navy", marginTop: "20px" }} />
                            : <FaCaretRight style={{ fontSize: "2rem", color: "grey", marginTop: "20px" }} />}
                </span>
            ),
            Cell: ({ row }) => <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                        })} >
                        {row.isExpanded
                            ? <FaCaretDown style={{ fontSize: "1.5rem", color: "navy" }} />
                            : <FaCaretRight style={{ fontSize: "1.5rem", color: "grey" }} />}
                    </span>,
            width: 50
              
        },
        {
            Header: "nr", accessor: "nrdoc", Cell: r =>
                <div align="center"><small>{r.row.original.nrdoc}</small></div>,
            width: 100
        },
        {
            Header: "datadoc", accessor: "dt", Cell: r =>
                <div align="center"><small>{r.row.original.dt}</small></div>,
            width: 100
        },
        {
            Header: "descriere", accessor: "necesitate", Cell: r =>
                <small>{r.row.original.necesitate}</small>
            , width: 500
        },
        {
            Header: "status", accessor: "status", Filter: SelectColumnFilter, Cell: r =>
                <div align="center"><small>{r.row.original.status}</small></div>,
            width: 150
        },
        {
            Header: "SCADENȚĂ", accessor: "due", Filter: SelectColumnFilter, Cell: r =>
                <div align="center"><small>{r.row.original.due}</small></div>,
            width: 150
        },
        {
            Header: "total", id: "total", 
            Cell: r =>
                <div align="right"><small>{r.row.original.total}</small> &nbsp;&nbsp;&nbsp;</div>,
            width: 150
        },
        {
            id: "actiuni", Cell: r => (
                <IconButton
                    size="small" color="primary"
                    onClick={() => props.history.push(`/referat/${r.row.original.id}`)}>
                    <FaArrowAltCircleRight />
                </IconButton>
            ),
            width: 50
        }

    ], [])

    

    return (
        <div style={{ width: "100%", overflow: "hidden" }}>
            <Container title={<Typography variant = "h5">
                <strong>Referatele mele </strong>
                {memoizedData && <small>({memoizedData.length})</small>}
            </Typography>}
                action={<IconButton color="primary" onClick = {() => props.history.push('/referat/nou')}>
                        <FaPlusCircle />
                    </IconButton>}
            >

                <ReactTable
                    data={memoizedData}
                    defaultPageSize={10}
                    // clickedRow = {row => props.history.push(`/referat/${row.id}`)}
                    columns={columns}
                    renderRowSubComponent={({row}) => (
                        <ReactTable 
                            style = {{background: "rgba(3,169,244,0.16)"}}
                            data = {row.original.products}
                            columns = {[
                                {
                                    Header: <div align ="center"><strong>#</strong></div>, 
                                    id: "itemid", 
                                    Cell: r => <div align = "center">{r.row.index+1}</div>
                                },
                                {
                                    Header: <div align ="left" style = {{paddingLeft: "10px"}}><strong>Denumire</strong></div>, 
                                    id: "DENUMIRE", 
                                    Cell: r => <div align = "left">{r.row.original.name}</div>
                                },
                                {
                                    Header:<div align ="left" style = {{paddingLeft: "10px"}}><strong>Preț</strong></div>, 
                                    id: "PREȚ", 
                                    Cell: r => <div align = "left">{r.row.original.price}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>TVA(%)</strong></div>, 
                                    id: "TVA", 
                                    Cell: r => <div align = "center">{r.row.original.vat}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>Cantit.</strong></div>, 
                                    id: "cantit", 
                                    Cell: r => <div align = "center">{r.row.original.amount}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>U.M.</strong></div>, 
                                    id: "um", 
                                    Cell: r => <div align = "center">{r.row.original.unit}</div>
                                },
                                {
                                    Header: <div align ="center" style = {{paddingLeft: "10px"}}><strong>CPV</strong></div>, 
                                    id: "cpv", 
                                    Cell: r => <div align = "center">
                                        {
                                            r.row.original.cpv && r.row.original.cpv.code 
                                                ? r.row.original.cpv.code 
                                                :"nedefint"
                                        }</div>
                                },

                            ]}

                        />
                    )}
                />


                {/* <pre>{user && JSON.stringify(user, null, 4)}</pre> */}

            </Container>
        </div>

    )
}

export default ReferateleMele