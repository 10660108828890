import { AppBar, Button, CssBaseline, Drawer, Grid, IconButton, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useContext, Fragment } from 'react';
// import { useRouter } from 'next/router'
import { FaBookOpen, FaBriefcase, FaCalendarAlt, FaCog, FaPowerOff, FaToiletPaper } from 'react-icons/fa';
import { withRouter } from "react-router-dom";
// import Head from '../components/Head'
import Copyright from '../components/Copyright';
import storage from '../lib/storage';
import { Context } from './Store';
import Logo from '../assets/AQUIS.svg'



const drawerWidth = 100;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex', width: "100%"
  },
  toolbar: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 20px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    // whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      //    width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    // flexGrow: 1
    height: '100vh',
    overflow: 'auto',
    width: "100%",

  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),

  },
  backContainer: {
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    backgroundImage: `url('/cioara.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '5px solid red',
    padding: '20px',
    opacity: 0.07,
    zIndex: -1,
    zoom: 1
  },
  paper: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  wrapText: {
    wordWrap: 'break-word'
  }
}));



const Layout = (props) => {


  const theme = useTheme()
  const width = useMediaQuery(theme.breakpoints.up('sm'))
  const [state, dispatch] = useContext(Context)


  // const router = useHistory()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userModalCloseHandler = ev => {


  }

  // useEffect(() => {
  //   setOpen(width)
  // }, [width])



  const logoutHandler = () => {

    props.history.push('/login')
  }

  const UserData = usr => (
    <Grid container justify="flex-start" alignItems="flex-end">
      <Grid item xs={12}>
        <small>{usr.name} ({usr.username})</small>
      </Grid>
      <Grid item xs={12}>
        <small>
          email: {usr.email} / rol: {usr.role}
        </small>
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.root}>

      {/* <Head title = "MONITOR OFICIAL LOCAL"/> */}
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton

            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justify="space-between" alignItems="flex-end">
              
            <img src={Logo} style={{ height: "48px", width: "auto" }} />


            <Grid item>
              <Grid container>
                <Grid item align="right">
                  {state && <UserData {...state} />}
                </Grid>
                <Grid item align="right">
                  <IconButton color="inherit" onClick={
                    () => {
                      storage.remove()
                      dispatch({}); props.history.push("/login")
                    }}
                    style={{ marginRight: "8px" }}
                  >
                    <FaPowerOff />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>


          </Grid>


        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}

        open={open}
      >
        <div className={classes.toolbarIcon}>

          <IconButton onClick={handleDrawerClose} aria-label="close side menu">
            <ChevronLeftIcon />
          </IconButton>


        </div>

        <Grid container justify="space-around" alignItems="center" style={{ marginTop: '1em' }}>

          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/')}>
              <span style={{ display: "block" }}>
                <FaBriefcase style={{ fontSize: '3em', color: 'chocolate' }} />
                <Typography variant="caption" color="inherit">REFERATELE MELE</Typography>
              </span>
            </Button>
          </Grid>
          {(state.role === "admin" || state.role === "app") && (
            <Fragment>
            <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
              <Button onClick={() => props.history.push('/registru')}>
                <span style={{ display: "block" }}>
                  <FaBookOpen style={{ fontSize: '3em', color: 'blueviolet' }} />
                  <Typography variant="caption">REGISTRU</Typography>
                </span>
              </Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/programe')}>
              <span style={{ display: "block" }}>
                <FaCalendarAlt style={{ fontSize: '3em', color: 'coral' }} />
                <Typography variant="caption">PROGRAME</Typography>
              </span>
            </Button>
          </Grid>  
         


          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/rapoarte/program-anual')}>
              <span style={{ display: "block" }}>
                <FaToiletPaper style={{ fontSize: '3em', color: 'darkcyan' }} />
                <Typography variant="caption">RAPOARTE</Typography>
              </span>
            </Button>
          </Grid>
          
        
 

          <Grid item xs={12} style={{ textAlign: 'center', opacity: '0.7', marginBottom: '2rem' }} >
            <Button onClick={() => props.history.push('/configurari')}>
              <span style={{ display: "block" }}>
                <FaCog style={{ fontSize: '3em', color: 'indianred' }} />
                <Typography variant="caption">SETĂRI</Typography>
              </span>
            </Button>
          </Grid>
          </Fragment>
 )}



        </Grid>



        {/* <Divider /> */}
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content} >
        <div className={classes.appBarSpacer} style={{ marginBottom: "-10px" }} />
        {/* <Container maxWidth="xl" className={classes.container} > */}

        {props.children}


        {/* </Container> */}
        <div className={classes.backContainer} />
        <Copyright />
      </main>
    </div>
  );
}

export default withRouter(Layout)