import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Context} from '../../components/Store'
import {useMutation} from '@apollo/client'
import {Grid, Toolbar, makeStyles, IconButton, Chip, Fab, Badge} from '@material-ui/core'
import RichInput from '../../components/RichInput'
import {FaCheck, FaCheckCircle, FaPlusCircle, FaTimesCircle} from 'react-icons/fa'
import { Alert } from '@material-ui/lab'
import Confirm from '../../components/Confirm'
import {DEPARTMENT_MUTATION, DEPARTMENT_REMOVE_MUTATION} from '../../apollo/mutation'

const useStyles = makeStyles(theme=>({
    toolbar: {
        background: theme.palette.grey[200], fontWeight: 800, marginBottom: theme.spacing(2),
        display: "flex", justifyContent: "space-between",
        paddingRight: theme.spacing(0.5)
    },
    container: {padding: theme.spacing(1), border: "1px solid grey", maxHeight: "60vh", overflowY: "scroll"}
}))

export default function Departamente(props) {
    const classes = useStyles()
    const [state, setState] = useContext(Context)
    const [departments, setDepartments] = useState()
    const [department,  setDepartment ] = useState()
    //for destroy model
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    //---
    const [ indexes, setIndexes ] = useState([])
    const [craItem, __ ] = useMutation(DEPARTMENT_MUTATION)
    const [rmItem, rmItemDispatch] = useMutation(DEPARTMENT_REMOVE_MUTATION)
    
    useEffect(() => {
        if ( props && props.departments ) {setDepartments(props.departments)}
    }, [props])
    
    const inputHandler = i => ev => {
        let d = [...departments].map( el => ({...el}))
        d[i]['name'] = ev.target.value
        setDepartments(d)
        if ( !indexes.includes(i)) {setIndexes([...indexes, i])}
    }

    const removeConfirmHandler = (item, index) => () => {
        if ( !item.id ) {
            let d = [...departments]
            d.splice(index,1)
            setDepartments(d)
        } else {
            setCurrentItem(item); setCurrentIndex(index)
        }
    }

    const removeItemHandler = ev => {
        if (ev) {
            rmItem({variables: {id:currentItem.id}}).then( 
                r => {
                    let newDeps = [...departments]
                    newDeps.splice(currentIndex, 1)
                    setDepartments(newDeps)
                    props.onSubmit(true)
                },
                err => console.log("remove item error:", err)
            )
           
        }
        setCurrentItem(); setCurrentItem()
    }

    const submitHandler = (elem) => () => {
        let ix = [...indexes]
        let i = ix.filter(el => el === elem.id)[0]
        ix.splice(i,1)
        setIndexes(ix)
        console.log("current_company_id", state.current_company_id)
        craItem({variables: {data: {
            id: elem.id, name: elem.name, desc: elem.desc, company_id: state.current_company_id
        }}}).then( 
            r => props.onSubmit(true),
            err => console.log("err:", err)
        )
        
    }

    const createItemHandler = () => setDepartments([...departments, {name: ""}])

    return (
        <div>
            <Toolbar variant = "dense" className = {classes.toolbar}>
                ADMINISTRARE DEPARTAMENTE
                <div align = "right">
                    <IconButton size = "medium" color = "primary" onClick = {createItemHandler}>
                        <FaPlusCircle/>
                    </IconButton>
                </div>
            </Toolbar>
            <div className = {classes.container}>
            <Grid container spacing = {1} alignItems = "center">
            {
                departments && departments.map( (e,i) => (
                        <Grid key = {i} item xs = {4}>
                            <RichInput 
                                value = {e.name} 
                                onChange ={inputHandler(i)} 
                                start = {<Badge badgeContent = {i+1} color="default" style = {{marginRight: "10px"}} />}
                                end = { 
                                    <div>
                                      
                                    <IconButton 
                                        disabled = { e && e.users && e.users.length > 0 }
                                        onClick = {removeConfirmHandler(e,i)} 
                                        color = "secondary" size = "small">
                                        <FaTimesCircle/>
                                    </IconButton>
                                    &nbsp;&nbsp;&nbsp;
                                    <IconButton 
                                        size = "small" color = "primary"
                                        disabled = {!indexes.includes(i)}
                                        onClick = {submitHandler(e)}
                                    >
                                        <FaCheckCircle/> 
                                    </IconButton>
                                    </div>
                                }
                                
                            />
                        </Grid>

                ))
            }
                <Grid item xs = {12}>
                    <Alert severity = "success">
                        Din motive de siguranță, ștergerea departamentelor care conțin utilizatori a fost dezactivată.<br/>
                        Pentru a șterge un astfel de departament, eliminații prima dată utilizatorii acestuia.
                    </Alert>
                </Grid>
             </Grid>
           </div>
            {/* <pre>{props && JSON.stringify(departments, null, 4)}</pre> */}
            <Confirm open = {currentItem} onClose = {removeItemHandler} />
        </div>
    )
}
