import { Alert } from '@material-ui/lab'
import React from 'react'

export default function Cpv() {
    return (
        <div>
            <Alert severity = "warning">OPȚIUNE ÎN DEZVOLTARE, DISPONIBILĂ ÎN CURÂND</Alert>
        </div>
    )
}
