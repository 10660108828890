import React, { Fragment } from 'react'
import { Tab, Tabs, Box, Typography, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab';

export default function ExportDate() {
    return (
        <Fragment>
            <Alert severity="error">
                Apăsați butonul de mai jos pentru a genera și descărca o copie de siguranță completă a datelor Dvs. din aplicație.
                Vâ recomandăm să efectuați această operațiune cel puțin săptămânal!
    </Alert>
            <div align="center" style={{ padding: "5rem" }}>
                <Button variant="contained" color="secondary" size="large">
                    BACKUP
   </Button>
            </div>
        </Fragment>
    )
}
