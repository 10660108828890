import { Button, Grid, TextField, makeStyles, Toolbar } from "@material-ui/core"
import { useEffect, useState, useContext } from "react"
import {Context} from '../../components/Store'
import { useMutation } from "@apollo/client"
import { COMPANY_MUTATION, COMPANY_REMOVE_MUTATION } from '../../apollo/mutation'
import RichInput from '../../components/RichInput'
import Confirm from '../../components/Confirm'

const useStyles = makeStyles(theme => ({
    inputProps: {
        fontSize: "0.8rem",fontWeight: 600
    },
    toolbar: {  background: theme.palette.grey[200], fontWeight: 800, marginBottom: theme.spacing(2)  }
}))
const PROPS_FIELDS = [
    {name: 'adresa', xs: 12, sm: 9},
    {name: 'cui', xs: 12, sm: 3}, 
    {name: 'loc', xs: 12, sm: 6}, 
    {name: 'jud', xs: 12, sm: 6},  
    {name: 'tel', xs: 12, sm: 4}, 
    {name: 'mobil', xs: 12, sm: 4}, 
    {name: 'fax', xs: 12, sm: 4}, 
    {name: 'email', xs: 12, sm: 6}, 
    {name: 'web', xs: 12, sm: 6}, 
]
const Unitate = props => {
    const classes = useStyles()
    const [company, setCompany] = useState()
    const [confirmClicks, setConfirmClicks] = useState(0)
    const [confirm, setConfirm] = useState()
    const [state, setState] = useContext(Context)
    const [submit, dispatch] = useMutation(COMPANY_MUTATION)
    const [rm, __] = useMutation(COMPANY_REMOVE_MUTATION)

    useEffect(() => {
        if (props.company) { setCompany({ ...props.company, props: JSON.parse(props.company.props) }) }
    }, [props])

    const inputHandler = name => ev => setCompany({ ...company, [name]: ev.target.value })

    const propsHandler = name => ev => setCompany({
        ...company,
        props: { ...company.props, [name]: ev.target.value }
    })

    

    useEffect(() => {
        if (dispatch.data) { props.onSubmit(true) }
    }, [dispatch])

    const submitHandler = () => {
        const data = {
            id: company.id,
            name: company.name,
            props: JSON.stringify(company.props)
        }
        console.log("submit data: ", data)
        submit({
            variables:
                { data: data }
        })
    }

    const createHandler = () => {
        submit({variables: {
            data: {
                name: "",
                props: JSON.stringify({})
            }
        }}).then( 
            r => {
                console.log(r.data)
                setCompany(r.data.company)
            },
            err => console.log("company creation error", err.toString())
        )
        
    }

    const removeConfirmOpenHandler = ev => {
        ev.preventDefault()
        console.log("c:", confirmClicks)
        if (confirmClicks >=5) {setConfirm(true)}
    }

    const companyRemoveHandler = ev => {
        if (ev) {
            console.log("company", company)
            rm({variables: {id:company.id}})
                .then( 
                    r => props.history.push('/configurari'),
                    err => console.log("rm error: ", err.toString())
                )
        } 
        setConfirm();setConfirmClicks(0)
       
        
    }

    return (
        <div>
            <Toolbar variant = "dense" className = {classes.toolbar}>
                <Grid container justify = "space-between">
                    ADMINSTRARE DATE UNITATE
                    {  (state.role === "app" || state.role === "admin") && (
                        <div>
                            <Button onClick = {createHandler}> ADAUGĂ UNITATE</Button>
                            <Button 
                                color = {confirmClicks < 5 ? "default" : "secondary"}
                                variant = {confirmClicks < 5 ? "text" : "contained"}
                                onClick = {()=>setConfirmClicks(confirmClicks+1)}
                                onContextMenu = {removeConfirmOpenHandler}>
                                STERGERE UNITATE
                            </Button>
                        </div>
                    )} 
                </Grid>
                
            </Toolbar>
            {company && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <RichInput
                            size="small" variant="outlined" fullWidth
                            label="nume unitate"
                            value={company.name||""}
                            onChange={inputHandler('name')}
                        />
                    </Grid>
                    {PROPS_FIELDS.map((e, i) => (
                        <Grid item xs={e.xs} sm={e.sm} key={i}>
                            <RichInput
                                size="small" variant="outlined" fullWidth
                                label={e.name}
                                value={company.props[e.name] || ""}
                                onChange={propsHandler(e.name)}
                            />
                        </Grid>
                    ))}


                    <Grid item xs={12} align="right">
                        <Button size="small" color="primary" variant="contained" onClick={submitHandler}>
                            CONFIRMĂ
                        </Button>
                    </Grid>
                </Grid>
            )}

           <Confirm open = {confirm} onClose ={companyRemoveHandler} />
        </div>

    )
}

export default Unitate