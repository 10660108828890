import { Fragment, useEffect, useState, useContext } from "react"
import { Context } from '../../components/Store'
import { useQuery } from '@apollo/client'
import { COMPANY_QUERY } from '../../apollo/query'
// import { siglaRoB64 } from "../../assets/siglaRoB64"
import moment from 'moment'
import { Grid, Typography, makeStyles, Table, TableHead, TableBody, TableRow, TableCell, createMuiTheme } from '@material-ui/core'
import { rowTotal } from '../../lib/compute'

const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1 },
    table: {
        tableLayout: "auto",
        width: "100%",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid grey",
            background: theme.palette.grey[200],
            wordWrap: "normal",
            fontSize: "0.7rem",
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid grey",
            fontSize: "0.7rem",
        },
        '&& tbody>tr>td': {
            wordWrap: "break-word",
            padding: theme.spacing(0.5)
        },
    }

}))


const AnexaAchizitiiDirecteTemplate = props => {
    const classes = useStyles()
    const [antet, setAntet] = useState()
    const [program, setProgram] = useState()
    const [state, __] = useContext(Context)
    const { data, loading, error } = useQuery(COMPANY_QUERY, {
        variables: {
            id: program && program.company_id
        }
    })

    useEffect(() => {
        if (props.data) setProgram({...props.data, referate: [...props.data.referate]})
    }, [props.data])

    useEffect(() => {
        console.log("populating antet...", data && data.company)
        if (data && data.company) { setAntet(data.company) }
        
    }, [data])

    const ref2products = referate => [...referate].reduce((acc, item) => {
        return [...acc, ...item.products]
    }, [])

    return (
        <Fragment>
            {program && antet && (
                <Fragment>
                    <Grid container justify="space-between" alignItems="flex-start">
                        {/* <Grid item xs={1}>
                            <img src={siglaRoB64} style={{ height: "80px", widTableCell: "auto" }} />
                        </Grid> */}
                        <Grid item xs={12}>
                            {antet.name}
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Typography variant="h5">
                                {program.name}
                            </Typography>
                            <Typography variant="body2">
                                nr. {program.nrdoc} din {moment(program.dTableCelloc).format('DD.MM.YYYY')}
                            </Typography>
                            <Typography variant = "caption">
                                ANEXA ACHIZITII DIRECTE
                            </Typography>
                        </Grid>
                        {/* <Grid item xs = {12} align = "left"> */}

                        {/* <pre>{JSON.stringify(ref2products(program.referate, null,4))}</pre> */}
                        {/* </Grid> */}

                    </Grid>
                    <Table className={classes.table} cellSpacing={0}>
                        <TableHead>
                            <TableRow>
                                <TableCell width  = "5%" align ="center">Nr.crt</TableCell>
                                <TableCell width  = "40%" align ="left">Obiectul achiziției directe</TableCell>
                                <TableCell width = "10%" align ="center">Cod CPV</TableCell>
                                <TableCell width  = "10%" align ="center">Valoarea estimată <br /> (LEI, fără TVA)</TableCell>
                                <TableCell width  = "15%" align ="center">Sursa de finanțare</TableCell>
                                <TableCell width = "10%" align ="center">Data estimată <br />pentru inițiere</TableCell>
                                <TableCell width = "10%" align ="center">Data estimată pentru finalizare</TableCell>

                            </TableRow>
                        </TableHead>
                        {/* {ref2products(program.referate).length} */}
                        <TableBody>
                            {console.log("refs:", program && program.referate)}
                            {ref2products(program.referate).map((e, i) => {
                                console.log("i:", e)
                                return (

                                <TableRow key={i}>
                                    <TableCell  align ="center">{i + 1}</TableCell>
                                    <TableCell>{e.name}</TableCell>
                                    <TableCell align ="center">{e.cpv ? e.cpv.code : "---"}</TableCell>
                                    <TableCell align ="right">{rowTotal(e, false).toFixed(2)}</TableCell>
                                    <TableCell align ="center">{e.sursa && e.sursa.name}</TableCell>
                                    <TableCell align ="center">{e.start ? moment(e.start).format('DD.MM.YYYY') : "- - - "}</TableCell>
                                    <TableCell align ="center">{e.end ? moment(e.end).format('DD.MM.YYYY') : "- - - "}</TableCell>
                                </TableRow>

                            )})}
                        </TableBody>
                    </Table>
                </Fragment>
            )}
        </Fragment>

    )
}

export default AnexaAchizitiiDirecteTemplate